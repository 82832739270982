import { getDoc } from '@/configs/doc-config'
import { getParentDeptsById } from '@/api/sysadmin/depts/depts'

export default {
  data() {
    return {
      user: {
        appdate: '', // 申请日期
        applicant: '', // 申请人id
        appName: '', // 申请人名称
        appUserCode: '', // 申请人编码
        post: '', //岗位
        contact: '', // 联系方式
        region: '', // 大区id
        regionName: '', // 大区名称
        province: '', // 省办id
        provinceName: '', // 省办名称
        locality: '', // 地办id
        localityName: '', // 地办名称
        department: '', // 部门id
        departmentName: '', // 部门名称
        conferenceType: '', // 所属层级
        conferenceTypeName: '', // 所属层级名称
        localityDTO: {},
        regionDTO: {},
        provinceDTO: {}
      },

      userForm: [
        { label: '申请人编码', prop: 'appUserCode', disabled: true },
        { label: '申请人', prop: 'appName', disabled: true },
        { label: '岗位', prop: 'post', disabled: true },
        { label: '联系方式', prop: 'contact', disabled: false },
        { label: '申请日期', prop: 'appdate', disabled: true },
        { label: '大区', prop: 'regionName', disabled: true },
        { label: '省办', prop: 'provinceName', disabled: true },
        { label: '地办', prop: 'localityName', disabled: true },
        { label: '费用承担部门', prop: 'departmentName', disabled: true },
        { label: '所属层级', prop: 'conferenceTypeName', disabled: true }
      ]
    }
  },

  async created() {
    if (this.type === 'add') {
      this.user.applicant = this.$store.getters.uid
      this.user.appName = this.$store.getters.username
      this.user.contact = this.$store.getters.contactinfo
      this.user.post = this.$store.getters.vnote
      this.user.appUserCode = this.$store.getters.userCode
      this.user.appdate = this.$date().format('YYYY-MM-DD')

      const depts = this.$store.getters.depts // 部门
      await this.getParentDept(depts[0].did)
    }

    getDoc('022', res => {
      this.userForm[9].options = res
      this.userForm[9].options.shift()
    })
  },

  methods: {
    /**
     * 获取大区、省办和地办的三级结构
     * @param {String} did 最后一级组织的主键
     */
    getParentDept(did) {
      getParentDeptsById(did).then(res => {
        const [regionDTO, provinceDTO, localityDTO] = res
        this.user.regionDTO = regionDTO
        this.user.provinceDTO = provinceDTO
        this.user.localityDTO = localityDTO

        if (regionDTO) {
          this.user.region = regionDTO.did
          this.user.regionName = regionDTO.deptname
        }

        if (provinceDTO) {
          this.user.province = provinceDTO.did
          this.user.provinceName = provinceDTO.deptname
        }

        if (localityDTO) {
          this.user.locality = localityDTO.did
          this.user.localityName = localityDTO.deptname
        }
      })
    }
  }
}
