import Performance from '@/views/performance'
export default {
  path: 'performance',
  name: 'performance',
  component: Performance,
  redirect: 'performance/performanceScore',
  meta: { title: '绩效考核' },
  children: [
    {
      // 产品经理行为绩效考核打分
      path: 'performanceScore',
      name: 'performanceScore',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/score'
        ),
      meta: { title: '行为绩效考核打分', menuKey: 'performanceScore' }
    },
    {
      // 产品经理行为绩效考核打分详情
      path: 'performScoreForm',
      name: 'performScoreForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/score/Form'
        ),
      meta: {
        title: '行为绩效考核打分详情',
        menuKey: 'performanceScore'
      }
    },

    {
      // 各省年发货指标金额
      path: 'performanceTarget',
      name: 'performanceTarget',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/target'
        ),
      meta: { title: '各省年发货指标金额', menuKey: 'performanceTarget' }
    },
    {
      // 各省年发货指标金额详情
      path: 'performTargetForm',
      name: 'performTargetForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/target/Form'
        ),
      meta: {
        title: '各省年发货指标金额详情',
        menuKey: 'performanceTarget'
      }
    },

    {
      // 各省发货指标月占比
      path: 'performanceProportion',
      name: 'performanceProportion',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/proportion'
        ),
      meta: { title: '各省发货指标月占比', menuKey: 'performanceProportion' }
    },
    {
      // 各省发货指标月占比详情
      path: 'performProportionForm',
      name: 'performProportionForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/proportion/Form'
        ),
      meta: {
        title: '各省发货指标月占比详情',
        menuKey: 'performanceProportion'
      }
    },

    {
      // 各省产品发货最低开票价
      path: 'performanceTicket',
      name: 'performanceTicket',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/ticket'
        ),
      meta: { title: '各省产品发货最低开票价', menuKey: 'performanceTicket' }
    },
    {
      // 各省产品发货最低开票价详情
      path: 'performTicketForm',
      name: 'performTicketForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/ticket/Form'
        ),
      meta: {
        title: '各省产品发货最低开票价详情',
        menuKey: 'performanceTicket'
      }
    },

    {
      // 奖励比例
      path: 'performanceReward',
      name: 'performanceReward',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/reward'
        ),
      meta: { title: '奖励比例', menuKey: 'performanceReward' }
    },
    {
      // 奖励比例详情
      path: 'performRewardForm',
      name: 'performRewardForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/reward/Form'
        ),
      meta: {
        title: '奖励比例详情',
        menuKey: 'performanceReward'
      }
    },

    {
      // 绩效奖金季度预计提
      path: 'performanceCalc',
      name: 'performanceCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/calc'
        ),
      meta: { title: '绩效奖金季度预计提', menuKey: 'performanceCalc' }
    },
    {
      // 绩效奖金季度预计提详情
      path: 'performanceCalcForm',
      name: 'performanceCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/calc/Form'
        ),
      meta: {
        title: '绩效奖金季度预计提详情',
        menuKey: 'performanceCalc'
      }
    },

    {
      // 超额奖励分配比例
      path: 'performanceExcessReward',
      name: 'performanceExcessReward',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/excessReward'
        ),
      meta: { title: '超额奖励分配比例', menuKey: 'performanceExcessReward' }
    },
    {
      // 超额奖励分配比例详情
      path: 'performExcessRewardForm',
      name: 'performExcessRewardForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/excessReward/Form'
        ),
      meta: {
        title: '超额奖励分配比例详情',
        menuKey: 'performanceExcessReward'
      }
    },

    {
      // 超额奖励计提
      path: 'perfExcessRewardCalc',
      name: 'perfExcessRewardCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/excessRewardCalc'
        ),
      meta: { title: '超额奖励计提', menuKey: 'perfExcessRewardCalc' }
    },
    {
      // 超额奖励计提详情
      path: 'perfExcessRewardCalcForm',
      name: 'perfExcessRewardCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/excessRewardCalc/Form'
        ),
      meta: {
        title: '超额奖励计提详情',
        menuKey: 'perfExcessRewardCalc'
      }
    },

    {
      // 新品考核分计提
      path: 'perfNewScoreCalc',
      name: 'perfNewScoreCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/newScore'
        ),
      meta: { title: '新品考核分计提', menuKey: 'perfNewScoreCalc' }
    },
    {
      // 超额奖励计提详情
      path: 'perfNewScoreCalcForm',
      name: 'perfNewScoreCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/newScore/Form'
        ),
      meta: {
        title: '新品考核分计提详情',
        menuKey: 'perfNewScoreCalc'
      }
    },

    {
      // 管理考核分
      path: 'performanceManagerScore',
      name: 'performanceManagerScore',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/cellanagerScore'
        ),
      meta: { title: '管理考核分', menuKey: 'performanceManagerScore' }
    },
    {
      // 管理考核分详情
      path: 'performanceManagerScoreForm',
      name: 'performanceManagerScoreForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/cellanagerScore/Form'
        ),
      meta: {
        title: '管理考核分详情',
        menuKey: 'performanceManagerScore'
      }
    },

    {
      // 绩效考核年度
      path: 'performanceYearCalc',
      name: 'performanceYearCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/yearCalc'
        ),
      meta: { title: '绩效考核年度计提', menuKey: 'performanceYearCalc' }
    },
    {
      // 绩效考核年度计提详情
      path: 'performanceYearCalcForm',
      name: 'performanceYearCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-performance" */ '@/views/performance/yearCalc/Form'
        ),
      meta: {
        title: '绩效考核年度计提详情',
        menuKey: 'performanceYearCalc'
      }
    }
  ]
}
