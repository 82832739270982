/* eslint-disable prettier/prettier */
import { getDoc } from '@/configs/doc-config'
import { 
  activityNatrueEnum,
  whetherDygProjEnum,
  whetherHeadQuartersProjEnum, 
  perferWhetherDygProj 
} from '@/shared/activity'
import moment from 'moment'

export default {
  data() {
    return {
      showMap: false, // 活动地点地图定位
      // 举办性质枚举类型
      activityNatrueEnum,
      // 是否为走进东阳光项目
      whetherDygProjEnum,
      // 是否为总部统一筹划项目
      whetherHeadQuartersProjEnum,
      // 判断显示是否走进东阳光项目的字段
      perferWhetherDygProj,

      businessTypeList: [], // 业务线枚举
      productList: [], // 产品枚举
      activityFormList: [], // 举办形式

      activity: {
        conferenceName: '', // 会议名称
        conferenceCode: '', // 会议编码
        businessType: '', // 业务线（枚举）
        product: '', // 产品（枚举）
        activityForm: '', // 举办形式
        activityNatrue: '', // 举办性质(自办/协办/自办+协办)
        actualActivityPlace: '', // 具体活动地点
        latitude: '',  // 具体活动地点经纬度
        longitude: '', // 具体活动地点经纬度
        actualActivityStdate: '', // 活动实际开始日期
        actualActivityEddate: '', // 活动实际结束日期
        reimTurnout: '', // 参会客户到场数(不含讲者)
        speakerNum: '',// 讲者到场人数
        accompPersonNum: '', // 陪同人员人数
        internalnum: '',// 内部人员到场数
        whetherDygProj: '', // 是否为走进东阳光项目
        whetherHeadQuartersProj: '', // 是否为总部统一筹划项目
        headQuartersProjectId: '', // 总部项目ID
        headQuartersProjectName: '', // 总部项目名称
       
        /**附件区 */
        signinForm: '', // 签到表
        pictures: '', // 现场照片
        consultationFeeProof: '', // 讲者咨询费凭证
        speakerReviewForm: '', // 点评会讲者点评表
        cooperationInfo: '',// 协办第三方(协办显示)
        onlineMeetingReport: '', // 线上会议报告(含线上显示)
        activitySummary: '', // 活动总结(废弃)

        /**多文字区 */
        activityExperience: '', // 活动心得体会(亮点和不足)
        actualActivityDesc: '' // 实际活动内容和效果描述
      },

      activityForm: [
        { label: '会议名称', prop: 'conferenceName', disabled: true },
        { label: '会议编码', prop: 'conferenceCode', disabled: true },
        { label: '业务线', prop: 'businessType', disabled: true, type: 'select', options: [] },
        { label: '产品', prop: 'product', disabled: true, type: 'select', options: []  },
        { label: '举办形式', prop: 'activityForm', disabled: false, type: 'select', options: [] },
        { label: '举办性质', prop: 'activityNatrue', disabled: true, type: 'select', options: [] },
        { label: '具体活动地点', prop: 'actualActivityPlace', disabled: false },
        { label: '活动实际开始日期', prop: 'actualActivityStdate', type: 'date', disabled: false, disabledDate: () => this.disabledStDate },
        { label: '活动实际结束日期', prop: 'actualActivityEddate', type: 'date', disabled: false, disabledDate: () => this.disabledEndDate },
        { label: '参会客户到场数(不含讲者)', prop: 'reimTurnout', disabled: false, validate: 'number' },
        { label: '讲者到场人数', prop: 'speakerNum', disabled: false, validate: 'number' },
        { label: '内部人员数', prop: 'accompPersonNum', disabled: false, validate: 'number' }, // 原字段名称陪同人员人数，改为内部人员数
        // { label: '内部人员到场数', prop: 'internalnum', disabled: false, validate: 'number' },
        { label: '是否为走进东阳光项目', type: 'select', prop: 'whetherDygProj', disabled: false, options: [], show: () => this.isShowDygProj },
        { label: '是否为总部统一筹划项目', prop: 'whetherHeadQuartersProj', disabled: false, type: 'select', options: [] },
        { label: '总部项目名称', prop: 'headQuartersProjectId', type: 'select', disabled: false, options:[], show: () => this.isShowWHQProj },
      ],

      activityFileForm: [
        { label: '签到表', prop: 'signinForm', disabled: false, deprecated: () => this.isAfterSignTable },
        { label: '现场照片', prop: 'pictures', disabled: true, deprecated: () => this.isAfterPictures },
        { label: '讲者咨询费凭证', prop: 'consultationFeeProof', disabled: false, notVerity: true },
        { label: '点评会讲者点评表', prop: 'speakerReviewForm', disabled: true, show: () => this.isShowMeeting },
        { label: '协办第三方资料', prop: 'cooperationInfo', disabled: true, show: () => this.isShowCo },
        { label: '线上会议报告', prop: 'onlineMeetingReport', disabled: true, show: () => this.isShowOnline   },
        { label: '活动总结', prop: 'activitySummary', disabled: true, notVerity: true, deprecated: true },
      ],

      activityTextareaForm: [
        { label: '实际活动内容和效果描述', prop: 'actualActivityDesc', disabled: false },
        { label: '活动心得体会(亮点和不足)', prop: 'activityExperience', disabled: false},
      ]
    }
  },

  computed: {
    // 线上显示
    isShowOnline() {
      if(!this.activity.activityForm) return false

      const target = this.activityForm[4].options.find(item => item.value === this.activity.activityForm)
      if(!target) return false

      const text = target.text 
      return text.indexOf('线上') > -1
    },
    // 直播显示
    isShowLiveStreaming() {
      if(!this.activity.activityForm) return false

      const target = this.activityForm[4].options.find(item => item.value === this.activity.activityForm)
      if(!target) return false

      const text = target.text 
      return text.indexOf('+直播') > -1
    },
    // 协办显示
    isShowCo() {
      return this.activity.activityNatrue.indexOf('协办') > -1
    },

    // 活动类型含点评会显示
    isShowMeeting() {
      const acttype = this.standard.activityTypeStdDTO.acttype
      return acttype.indexOf('点评会') > -1
    },

    // 是否为总部统一筹划项目
    isShowWHQProj() {
      return this.activity.whetherHeadQuartersProj === '是'
    },

     // 是否为走进东阳光项目
    isShowDygProj() {
      const { activitytype = '', activityTypeStdDTO = {} } = this.standard
      const { acttype = '' } = activityTypeStdDTO

      let show = false
      if(activitytype && acttype) {
        for (const activityName of this.perferWhetherDygProj) {
          if(acttype.indexOf(activityName)) {
            show = true
            break
          }
        }
      }
      return show
    }
  },

  created() {
    // 业务线
    getDoc('200', res => {
      this.businessTypeList = res
      this.activityForm[2].options = res
    })
    // 产品
    getDoc('300', res =>{ 
      this.productList = res
      this.activityForm[3].options = res 
    })
    // 举办形式
    getDoc('053', res => { 
      this.activityFormList = res
      this.activityForm[4].options = res 
    })

    // 总部项目名称
    getDoc('070', res => { 
      this.activityForm[14].options = res 
    })

    // 举办性质
    this.activityForm[5].options = this.activityNatrueEnum
    // 是否为走进东阳光项目
    this.activityForm[12].options = this.whetherDygProjEnum
    // 是否为总部统一筹划项目
    this.activityForm[13].options = this.whetherHeadQuartersProjEnum
  },

  methods: {
    moment,

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
     disabledStDate(time) {
      return (
        time && time >= moment(this.activity.actualActivityEddate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.activity.actualActivityStdate).add(-1, 'day').endOf('day')
      )
    },

    onDateChange([, dateString], item){
      this.activity[item.prop] = dateString
    },

    // 活动信息从地图选取的信息
    getActivityPlace(info) {
      const { address, position } = info
      this.activity.actualActivityPlace = address
      this.activity.latitude = position.lat
      this.activity.longitude = position.lng
    }
  }
}
