import { getDoc } from '@/configs/doc-config'
import { getParentDeptsById } from '@/api/sysadmin/depts/depts'

export default {
  data() {
    return {
      conferenceList: [],
      user: {
        appdate: '', // 申请日期
        applicant: '', // 申请人id
        appName: '', // 申请人名称
        appUserCode: '', // 申请人编码
        post: '', //岗位
        contact: '', // 联系方式
        region: '', // 大区id
        regionName: '', // 大区名称
        province: '', // 省办id
        provinceName: '', // 省办名称
        locality: '', // 地办id
        localityName: '', // 地办名称
        department: '', //部门id
        conferenceType: '', // 所属层级
        localityDTO: {},
        regionDTO: {},
        provinceDTO: {}
      },

      userForm: [
        { label: '申请人编码', prop: 'appUserCode', disabled: true },
        { label: '申请人', prop: 'appName', disabled: true },
        { label: '岗位', prop: 'post', disabled: true },
        { label: '联系方式', prop: 'contact', disabled: false },
        { label: '申请日期', prop: 'appdate', disabled: true },
        { label: '大区', prop: 'regionName', disabled: true },
        { label: '省办', prop: 'provinceName', disabled: true },
        { label: '地办', prop: 'localityName', disabled: true },
        {
          label: '费用承担部门',
          prop: 'department',
          disabled: false,
          type: 'select',
          options: []
        },
        {
          label: '所属层级',
          prop: 'conferenceType',
          disabled: true,
          type: 'select',
          options: []
        }
      ]
    }
  },

  async created() {
    if (this.type === 'add') {
      const depts = this.$store.getters.depts // 部门
      this.user.applicant = this.$store.getters.uid
      this.user.appName = this.$store.getters.username
      this.user.contact = this.$store.getters.contactinfo
      this.user.post = this.$store.getters.vnote
      this.user.appdate = this.$date().format('YYYY-MM-DD')
      this.user.appUserCode = this.$store.getters.userCode
      this.userForm[8].options = this.changeDeptProps(depts)

      if (depts[0]) {
        this.user.department = depts[0].did
        await this.getParentDept(depts[0].did)
      } else {
        this.$message.warning('未获取到费用承担部门，请分配后再操作')
      }
    }

    getDoc('022', res => {
      this.conferenceList = res
      this.userForm[9].options = res
      this.userForm[9].options.shift()

      if (this.type === 'add') this.changeConferenceType(this.user.department)
    })
  },

  methods: {
    changeDeptProps(depts) {
      return depts.map(item => ({
        text: item.deptname,
        value: item.did,
        deptdesc: item.deptdesc,
        deptNature: item.deptNature
      }))
    },

    /**
     * 获取大区、省办和地办的三级结构
     * @param {String} did 最后一级组织的主键
     */
    getParentDept(did) {
      getParentDeptsById(did).then(res => {
        const [regionDTO, provinceDTO, localityDTO] = res
        this.user.regionDTO = regionDTO
        this.user.provinceDTO = provinceDTO
        this.user.localityDTO = localityDTO

        if (regionDTO) {
          this.user.region = regionDTO.did
          this.user.regionName = regionDTO.deptname
        }

        if (provinceDTO) {
          this.user.province = provinceDTO.did
          this.user.provinceName = provinceDTO.deptname
        }

        if (localityDTO) {
          this.user.locality = localityDTO.did
          this.user.localityName = localityDTO.deptname
        }
      })
    },

    onUserChange(value, prop) {
      // 费用承担部门改变
      if (prop === 'department') {
        this.user.region = ''
        this.user.regionName = ''
        this.user.province = ''
        this.user.provinceName = ''
        this.user.locality = ''
        this.user.localityName = ''
        this.getParentDept(value)
        this.changeConferenceType(value)
      }
    },

    // 费用承担部门改变式，所属层级也跟着变
    changeConferenceType(value) {
      // 费用承担部门列表
      const deptList = this.userForm[8].options
      // 所属层级列表
      const conferenceTypeList = this.userForm[9].options

      const targetDept = deptList.find(item => item.value === value)
      let target
      if (targetDept && targetDept.deptNature !== '总部') {
        const level = Number(targetDept.deptdesc)
        switch (level) {
          case 1:
            target = conferenceTypeList.find(item => item.text === '大区')
            break
          case 2:
            target = conferenceTypeList.find(item => item.text === '省区')
            break
          default:
            target = conferenceTypeList.find(item => item.text === '地区')
        }
      } else {
        target = conferenceTypeList.find(item => item.text === '总部')
      }

      this.user.conferenceType = target.value
    }
  }
}
