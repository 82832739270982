<template>
  <div class="w-full relative">
    <div class="example" v-if="loading">
      <a-spin class="loading-icon" size="large" :tip="tip" />
    </div>

    <replay-notify
      :show="
        showNotification &&
        !$route.query.todo &&
        type !== 'waitwork' &&
        status === '96'
      "
      @replay="replay"
    />

    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      class="button-group py-4"
      style="margin-right: 3.56%"
    >
      <a-col :span="12">
        <span v-if="meetingsTimeStr" style="padding-left: 32px; color: #4587f5">
          {{ meetingsTimeStr }}
        </span>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-radio-group
          :value="value"
          buttonStyle="solid"
          @change="changeButton"
        >
          <a-radio-button value="a" :disabled="isRead" @click="save">
            保存
          </a-radio-button>
          <a-radio-button value="b" :disabled="isRead" @click="submit">
            提交
          </a-radio-button>
          <a-radio-button
            value="c"
            :disabled="isRead || applicationform.id === ''"
            @click="del"
          >
            删除
          </a-radio-button>
        </a-radio-group>
        <a-button
          type="primary"
          class="ml-4"
          :disabled="
            status === '1' ||
            status === '5' ||
            status === '15' ||
            status === '0' ||
            status === '98' ||
            status === '99'
          "
          @click="getBack"
        >
          撤销
        </a-button>

        <a-button
          type="primary"
          style="margin-left: 1.5em; margin-right: 1.5em"
          @click="showModal = true"
        >
          打印总结单
        </a-button>
      </a-col>
    </a-row>

    <div class="mt-4" v-else>
      <a-row>
        <a-col :span="12">
          <span style="padding-left: 32px; color: #4587f5">
            {{ meetingsTimeStr }}
          </span>
        </a-col>
        <a-col :span="12" class="text-right">
          <a-button
            type="primary"
            class="mr-4"
            @click="openApp(applicationform.activityAppId)"
          >
            申请单
          </a-button>
          <a-button type="primary" class="mr-4" @click="showModal = true">
            打印总结单
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div class="bill-content">
      <user-info
        :userInfo.sync="userInfo"
        :type="type"
        :status="isRead"
        :isXSBX="true"
        @chooseConferenceTypeName="chooseConferenceTypeName($event)"
        @username="$emit('username', $event)"
        @updateItem="isUpdate = true"
      ></user-info>
      <activity-rem-info
        :activityRemInfo.sync="activityRemInfo"
        :status="isRead"
        :conferenceTypeName="conferenceTypeName"
        :hostingTypeName="hostingTypeName"
        @updateItem="isUpdate = true"
      ></activity-rem-info>
      <speaker-info
        v-if="[1, 2, 4].includes(getHostingType)"
        :speakerInfo.sync="speakerInfo"
        :status="isRead"
        :isXSBX="true"
        @updateItem="isUpdate = true"
      ></speaker-info>
      <reim-brandpre-info
        v-if="[1, 2, 4].includes(getHostingType)"
        :brandpreInfo.sync="brandpreInfo"
        :status="isRead"
        :conferenceTypeName="conferenceTypeName"
        @updateItem="isUpdate = true"
      />
      <ExpenseInfo
        v-if="[3, 4].includes(getHostingType)"
        :expenseInfo.sync="expenseInfo"
        :status="isRead"
        :isXSBX="true"
        @updateItem="isUpdate = true"
      ></ExpenseInfo>
      <fee-rem-info
        :feeReimInfo.sync="feeReimInfo"
        :status="isRead"
        :cooperationInfo="activityRemInfo.cooperationInfo"
        :conferenceTypeName="conferenceTypeName"
        :hostingTypeName="hostingTypeName"
        :infoPubAmount="expenseInfo.infoPubAmount"
        :budgetConsultTotal="speakerInfo.budgetConsultTotal"
        :brandpreMoneytotal="brandpreInfo.brandpreMoneytotal"
        @updateItem="isUpdate = true"
      ></fee-rem-info>
      <post-script
        billType="XSBX"
        :pid="this.pid"
        :uid="applicationform.applicant"
        :status="status"
      ></post-script>
      <oa-process
        v-if="applicationform.flowInfoDTO"
        :status="isRead"
        :summaryid="applicationform.flowInfoDTO.summaryid"
        :nextapproveman="applicationform.flowInfoDTO.nextapproveman"
        :curApprover="applicationform.flowInfoDTO.curApprover"
      ></oa-process>
      <comment
        ref="comment"
        :uid="applicationform.applicant"
        :billId="applicationform.id"
        :status="status"
      ></comment>
    </div>
    <print-wrapper ref="printWrapper" v-show="printVisible">
      <xsbx-print
        :datas="reimPrintData"
        :options="options"
        title="学术会议总结单"
      ></xsbx-print>
    </print-wrapper>
    <modal
      v-if="showModal"
      title="选择打印的信息"
      @handldClick="handldPrintlick"
      @close="showModal = false"
    >
      <div>
        <a-checkbox-group
          v-model="options"
          name="checkboxgroup"
          :options="plainOptions"
          @change="onPrintChange"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import {
  getActivityAppById,
  getOACancel2
} from '@/api/acdemicActive/acdemic-application'
import {
  getActivityReimbursement,
  getEditActivityReimbursement,
  getActivityReimbursementById,
  getDeleteActivityReimbursement,
  getSubmitXSBX
} from '@/api/acdemicActive/acdemic-expense'

import { getDeleteFiles } from '@/api/acdemicActive/upload'

import UserInfo from '@/views/activity/modules/activebill/UserInfo'
import ActivityRemInfo from '@/views/activity/modules/activebill/ActivityRemInfo'
import SpeakerInfo from '@/views/activity/modules/activebill/SpeakerInfo'
import ReimBrandpreInfo from '@/views/activity/modules/activebill/ReimBrandpreInfo'
import ExpenseInfo from '@/views/activity/modules/activebill/ExpenseInfo'
import FeeRemInfo from '@/views/activity/modules/activebill/FeeRemInfo'
import OaProcess from '@/views/activity/modules/activebill/OaProcess'
import PostScript from '@/views/activity/modules/activebill/PostScript'
import Comment from '@/views/activity/modules/activebill/Comment'
import PrintWrapper from 'vue-easy-print'
import XsbxPrint from '@/views/activity/modules/print/XsbxPrint'
import Modal from '@/components/Modal'
import ReplayNotify from '@/components/ReplayNotify'
import { isDisabled, verifier } from '@/utils/util'
import { getCrmHttp } from '../modules/util'
import { default as cloneDeep } from 'lodash/cloneDeep'

import {
  getUserInfo,
  getSpeakerInfo,
  getActivityRemInfo,
  getFeeRemInfo,
  getBrandpreInfo,
  getExpenseInfo
} from '@/views/activity/modules/util.js'

export default {
  name: 'AcademicExpenseTable',
  props: ['type', 'pid'],
  components: {
    ActivityRemInfo,
    UserInfo,
    SpeakerInfo,
    ReimBrandpreInfo,
    ExpenseInfo,
    FeeRemInfo,
    OaProcess,
    PostScript,
    Comment,
    PrintWrapper,
    XsbxPrint,
    Modal,
    ReplayNotify
  },
  data() {
    return {
      value: 'a',
      loading: false,
      showNotification: false,
      printVisible: false,
      tip: '正在加载...',
      showModal: false,
      isUpdate: false, //检查单据是否修改
      conferenceTypeName: '', //所属公司名称
      hostingTypeName: '', //举办类型名称
      userInfo: {}, //申请人和执行人信息
      speakerInfo: {}, //讲者信息
      activityRemInfo: {}, //活动信息,
      feeReimInfo: {}, //费用信息
      brandpreInfo: {}, //推广物料信息
      expenseInfo: {}, //品牌信息的费用明细
      status: '', //单据状态
      meetingsTimeStr: '', // 统计信息
      reimPrintData: {}, //总结单打印的数据
      options: ['flow', 'ps', 'comment'],
      plainOptions: [
        { label: '流程信息', value: 'flow' },
        { label: '发起人附言', value: 'ps' },
        { label: '审批意见', value: 'comment' }
      ],
      applicationform: {
        id: '',
        activityAppId: '',
        appdate: '',
        conferenceCode: '',
        conferenceName: '',
        conferenceType: '',
        businessType: '',
        product: '',
        applicant: '',
        post: '',
        excutorContact: '',
        region: '',
        province: '',
        locality: '',
        actualActivityPlace: '', //实际活动地点
        actualActivityStdate: '', //实际开始时间
        disabledEndDate: '', //实际结束时间
        reimTurnout: '', //参会人员到场人数
        internalnum: '', //内部人员到场人数
        signinForm: '', //签到表
        pictures: '', //现场照片
        isbrandpre: '', //是否有推广物料
        consultationFeeProof: '', //专家/讲者资讯费凭证
        activitySummary: '', //活动总结
        hostingType: '', // 举办类型
        articleNetworkLink: '', //信息发布-文章网络连接
        projectSummary: '', //信息发布-项目总结
        financialApprovalAmount: 0, //财务批准报销金额
        reimAmountApprove: 0, //推广物料报销总金额(小写)
        capitalForm: '', //批准报销总金额(大写)
        brandpreMoneytotal: 0, //推广物料-合计
        budgetMeals: 0, //餐费单价
        budgetAccommodation: 0, //住宿费单价
        budgetBooth: 0, //展地费单价
        budgetMeetingroom: 0, //会议费单价
        budgetConsult: 0, //讲者咨询费单价
        budgetCooperation: 0, //协办费单价
        budgetPreCost: 0, //预付金额
        budgetBrandpre: 0, //推广物料费单价
        budgetFlight: 0, //飞机费单价
        budgetTrain: 0, //高铁费单价
        budgetReception: 0, //接送费单价
        budgetMake: 0, //制作费单价
        budgetDesign: 0, //设计费单价
        budgetThirdparty: 0, //第三方会务人员费用单价
        budgetTotal: 0, //合计
        budgetApprovetotal: 0, //批准总预算
        budgetMealsNum: 0,
        budgetMealsTotal: 0,
        budgetAccommodationNum: 0,
        budgetAccommodationTotal: 0,
        budgetBoothNum: 0,
        budgetBoothTotal: 0,
        budgetMeetingroomNum: 0,
        budgetMeetingroomTotal: 0,
        budgetConsultNum: 0,
        budgetConsultTotal: 0,
        budgetCooperationNum: 0,
        budgetCooperationTotal: 0,
        budgetBrandpreNum: 0,
        budgetBrandpreTotal: 0,
        budgetFlightNum: 0,
        budgetFlightTotal: 0,
        budgetTrainNum: 0,
        budgetTrainTotal: 0,
        budgetReceptionNum: 0,
        budgetReceptionTotal: 0,
        budgetMakeNum: 0,
        budgetMakeTotal: 0,
        budgetDesignNum: 0,
        budgetDesignTotal: 0,
        budgetThirdpartyNum: 0,
        budgetThirdpartyTotal: 0,
        budgetServiceTotal: 0,
        otherFee: 0, // 其他费用
        registerFee: 0, // 注册费用
        onlineLiveBroadcastingFee: 0, // 线上直播平台费用
        infoPubAmount: 0, //品牌信息发布费用

        reimbudgetMeals: 0, //餐费单价
        reimbudgetAccommodation: 0, //住宿费单价
        reimbudgetBooth: 0, //展地费单价
        reimbudgetMeetingroom: 0, //会议费单价
        reimbudgetConsult: 0, //讲者咨询费单价
        reimbudgetCooperation: 0, //协办费单价
        reimbudgetBrandpre: 0, //推广物料费单价
        reimbudgetFlight: 0, //飞机费单价
        reimbudgetTrain: 0, //高铁费单价
        reimbudgetReception: 0, //接送费单价
        reimbudgetMake: 0, //制作费单价
        reimbudgetDesign: 0, //设计费单价
        reimbudgetThirdparty: 0, //第三方会务人员费用单价
        reimbudgetTotal: 0, //合计
        reimbudgetApprovetotal: 0, //批准总预算
        reimbudgetMealsNum: 0,
        reimbudgetMealsTotal: 0,
        reimbudgetAccommodationNum: 0,
        reimbudgetAccommodationTotal: 0,
        reimbudgetBoothNum: 0,
        reimbudgetBoothTotal: 0,
        reimbudgetMeetingroomNum: 0,
        reimbudgetMeetingroomTotal: 0,
        reimbudgetConsultNum: 0,
        reimbudgetConsultTotal: 0,
        reimbudgetCooperationNum: 0,
        reimbudgetCooperationTotal: 0,
        reimbudgetBrandpreNum: 0,
        reimbudgetBrandpreTotal: 0,
        reimbudgetFlightNum: 0,
        reimbudgetFlightTotal: 0,
        reimbudgetTrainNum: 0,
        reimbudgetTrainTotal: 0,
        reimbudgetReceptionNum: 0,
        reimbudgetReceptionTotal: 0,
        reimbudgetMakeNum: 0,
        reimbudgetMakeTotal: 0,
        reimbudgetDesignNum: 0,
        reimbudgetDesignTotal: 0,
        reimbudgetThirdpartyNum: 0,
        reimbudgetThirdpartyTotal: 0,
        reimbudgetServiceTotal: 0,
        reimOtherFee: 0, // 其他费用
        reimRegisterFee: 0, // 注册费用
        reimOnlineLiveBroadcastingFee: 0,
        reimInfoPubAmount: 0, //信息发布费用（申请单带过来的）

        activitytype: null,
        conferenceTypeDTO: null,
        businessTypeDTO: null,
        activityNatrueDTO: null,
        provinceDTO: null,
        regionDTO: null,
        localityDTO: null,
        productDTO: null,
        hostingTypeDTO: null,
        speakers: [],
        brandpreInfoDTOs: [],
        expenseDetailDTOs: [], //品牌发布信息
        activityTypeStdDTO: {},
        flowInfoDTO: {
          status: '1'
        }
      }
    }
  },
  computed: {
    getUid() {
      return this.$store.getters.uid
    },
    isRead() {
      return isDisabled(this.status)
    },
    showAvatar() {
      return this.$store.getters['protal']
    },
    getHostingType() {
      if (!this.hostingTypeName) return 1

      let active = false
      let purtive = false
      if (this.hostingTypeName.indexOf('学术推广活动') !== -1) active = true
      if (this.hostingTypeName.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    }
  },
  created() {
    this.loading = true
    if (this.type === 'add') {
      getActivityAppById(this.pid).then(response => {
        this.applicationform = this.filterAppfrom(
          Object.assign(this.applicationform, response)
        )
        // 填写总结单的日期
        this.$set(
          this.applicationform,
          'activityAppdate',
          this.applicationform.appdate
        )
        this.applicationform.appdate = this.$date().format('YYYY-MM-DD')
        if (response.conferenceTypeDTO) {
          this.conferenceTypeName = response.conferenceTypeDTO.name
        }
        if (response.hostingTypeDTO) {
          this.hostingTypeName = response.hostingTypeDTO.name
        }
        this.userInfo = getUserInfo(this.applicationform)
        this.activityRemInfo = getActivityRemInfo(this.applicationform)

        this.speakerInfo = this.clearSpeaker(
          getSpeakerInfo(this.applicationform)
        )
        this.brandpreInfo = this.clearBrandpre(
          getBrandpreInfo(this.applicationform)
        )
        this.expenseInfo = this.clearExpense(
          getExpenseInfo(this.applicationform)
        )
        this.feeReimInfo = this.clearFeeReimInfo(
          getFeeRemInfo(this.applicationform)
        )
        this.status = '1'
        this.reimPrintData = this.applicationform

        const { meetingsTimeStatistics: m } = this.applicationform
        if (m) {
          this.mTimeStr = `${m.activityTypeName}, ${m.yearMonth}年`
          if (m.areaYearTimes || m.provinceYearTimes || m.localityYearTimes) {
            this.mTimeStr += `${this.form.regionName}, `
            this.mTimeStr += `大区层级共${m.areaYearTimes || '0'}场, `
            this.mTimeStr += `省区层级共${m.provinceYearTimes || '0'}场, `
            this.mTimeStr += `地区层级共${m.localityYearTimes || '0'}场, `
          } else {
            this.mTimeStr += `总部, `
          }

          this.mTimeStr += `本次为‘费用承担部门’第${m.deptYearSeq}/${m.deptYearTimes}场`
        }

        this.$emit('status', this.status)
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    }
    if (this.type === 'check' || this.type === 'waitwork') {
      getActivityReimbursementById(this.pid).then(response => {
        this.applicationform = Object.assign(this.applicationform, response)
        if (response.conferenceTypeDTO) {
          this.conferenceTypeName = response.conferenceTypeDTO.name
        }
        if (response.hostingTypeDTO) {
          this.hostingTypeName = response.hostingTypeDTO.name
        }
        this.userInfo = getUserInfo(response)
        this.speakerInfo = getSpeakerInfo(response)
        this.brandpreInfo = getBrandpreInfo(response)
        this.activityRemInfo = getActivityRemInfo(response)
        this.feeReimInfo = getFeeRemInfo(response)
        this.expenseInfo = getExpenseInfo(response)
        if (!response.flowInfoDTO) {
          this.status = '1'
        } else {
          this.status = response.flowInfoDTO.status
        }

        this.reimPrintData = this.applicationform
        this.showNotification = this.status === '96'

        const { meetingsTimeStatistics: m } = this.applicationform
        if (m) {
          this.mTimeStr = `${m.activityTypeName}, ${m.yearMonth}年`
          if (m.areaYearTimes || m.provinceYearTimes || m.localityYearTimes) {
            this.mTimeStr += `${this.form.regionName}, `
            this.mTimeStr += `大区层级共${m.areaYearTimes || '0'}场, `
            this.mTimeStr += `省区层级共${m.provinceYearTimes || '0'}场, `
            this.mTimeStr += `地区层级共${m.localityYearTimes || '0'}场, `
          } else {
            this.mTimeStr += `总部层级共${m.yearTimes || '0'}场，`
          }

          this.mTimeStr += `本次为‘费用承担部门’第${m.deptYearSeq}/${m.deptYearTimes}场`
        }

        this.$emit('status', this.status)
        this.$emit('userInfo', this.userInfo)
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    }
  },
  methods: {
    changeButton() {
      this.value = 'a'
    },

    //选取的会议类型
    chooseConferenceTypeName(value) {
      this.conferenceTypeName = value
    },

    replay() {
      this.$refs.comment.showLastComment()
      this.showNotification = false
      this.$nextTick(() => {
        this.$store.commit('rightContent/SCROLL_TO_END')
      })
    },

    //保存
    save(callback) {
      this.assgin()
      this.loading = true
      this.tip = '正在保存...'
      if (this.applicationform.id) {
        this.applicationform = this.filterData(this.applicationform)
        const data = cloneDeep(this.applicationform)
        getEditActivityReimbursement(data)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.loading = false
            this.$emit(this.status)
            if (this.showAvatar) {
              window.close()
            }
            if (!this.isUpdate) {
              //未做更改，点击保存按钮
              this.$router.replace({ name: 'activityReimbursement' })
            } else {
              // 做更改保存，非点击按钮触发事件
              if (typeof callback === 'function') {
                callback(true)
              } else {
                //点击按钮触发事件
                this.isUpdate = false
                this.$router.replace({ name: 'activityReimbursement' })
              }
            }
          })
          .catch(() => {
            this.loading = false
            callback(false)
          })
      } else {
        this.applicationform = this.filterData(this.applicationform)
        const data = cloneDeep(this.applicationform)
        getActivityReimbursement(data)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.loading = false
            this.$emit(this.status)
            if (this.showAvatar) {
              window.close()
            }
            if (!this.isUpdate) {
              //未做更改，点击保存按钮
              this.$router.replace({ name: 'activityReimbursement' })
            } else {
              // 做更改保存，非点击按钮触发事件
              if (typeof callback === 'function') {
                callback(true)
              } else {
                //点击按钮触发事件
                this.isUpdate = false
                this.$router.replace({ name: 'activityReimbursement' })
              }
            }
          })
          .catch(() => {
            this.loading = false
            callback(false)
          })
      }
    },

    //提交
    submit() {
      this.assgin()
      this.isUpdate = false
      if (verifier(this.applicationform)) {
        this.loading = true
        this.tip = '正在提交...'
        this.applicationform = this.filterData(this.applicationform)
        const data = cloneDeep(this.applicationform)
        getSubmitXSBX(data)
          .then(() => {
            this.delUploadFile()
            this.$message.success('提交成功')
            this.loading = false
            if (this.showAvatar) {
              //门户保存crm单据时，直接关闭窗口
              window.close()
            }
            this.$router.replace({ name: 'activityReimbursement' })
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    //删除
    del() {
      this.isUpdate = false
      this.$confirm({
        title: '是否要删除该学术推广活动总结单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getDeleteActivityReimbursement(this.applicationform.id).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) {
              window.close()
            }
            this.$router.replace({ name: 'activityReimbursement' })
          })
        },
        onCancel: () => {}
      })
    },

    // 撤销
    getBack() {
      this.$confirm({
        title: '是否要撤销该学术活动总结单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.applicationform.id,
            'XSBX',
            this.applicationform.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.status = '5'
            this.feeReimInfo.reimAmountApprove = 0 //批准总报销为0
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    // 提交数据时，跟根据举办类型初始化不同类型的数据
    filterData(apply) {
      const fileIds = []

      if ([4].includes(this.getHostingType)) return apply

      if ([1, 2].includes(this.getHostingType)) {
        apply.infoPubAmount = 0
        apply.expenseDetailDTOs = []

        if (apply.articleNetworkLink) {
          const articleNetworkLink = JSON.parse(apply.articleNetworkLink)
          articleNetworkLink.forEach(item => {
            fileIds.push(item.id)
          })
          apply.articleNetworkLink = ''
        }
        if (apply.projectSummary) {
          const projectSummary = JSON.parse(apply.projectSummary)
          projectSummary.forEach(item => {
            fileIds.push(item.id)
          })
          apply.projectSummary = ''
        }
      }

      if ([3].includes(this.getHostingType)) {
        apply.activityPlace = ''
        apply.activityTheme = ''
        apply.activityPurpose = ''
        apply.speakerNum = null
        apply.preinvitednum = null
        apply.internalnum = null
        apply.hasconfContract = ''
        apply.confCompany = ''
        apply.contractMoney = ''
        apply.contractScan = ''
        apply.sponsor = '' //主办方名称
        apply.sponsorEstdate = ''
        apply.isinvitedspeaker = ''
        apply.isbrandpre = ''
        apply.budgetConsultTotal = 0
        apply.budgetCooperationTotal = 0
        apply.budgetBrandpreTotal = 0

        apply.speakers = []
        apply.brandpreInfoDTOs = []

        if (apply.signinForm) {
          const signinForm = JSON.parse(apply.signinForm)
          signinForm.forEach(item => {
            fileIds.push(item.id)
          })
          apply.signinForm = ''
        }
        if (apply.pictures) {
          const pictures = JSON.parse(apply.pictures)
          pictures.forEach(item => {
            fileIds.push(item.id)
          })
          apply.pictures = ''
        }
        if (apply.consultationFeeProof) {
          const consultationFeeProof = JSON.parse(apply.consultationFeeProof)
          consultationFeeProof.forEach(item => {
            fileIds.push(item.id)
          })
          apply.consultationFeeProof = ''
        }
        if (apply.activitySummary) {
          const activitySummary = JSON.parse(apply.activitySummary)
          activitySummary.forEach(item => {
            fileIds.push(item.id)
          })
          apply.activitySummary = ''
        }
      }

      if (fileIds.length) {
        getDeleteFiles(fileIds)
      }

      return apply
    },

    //合并单据信息
    assgin() {
      Object.assign(
        this.applicationform,
        this.userInfo,
        this.activityRemInfo,
        this.brandpreInfo,
        this.speakerInfo,
        this.expenseInfo,
        this.feeReimInfo
      )
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    },

    filterAppfrom(from) {
      from.activityAppId = from.id
      from.id = ''
      from.internalnum = '' //内部人员不使用申请单上的默认人数
      from.reimbudgetMeals = from.budgetMeals
      from.reimbudgetAccommodation = from.budgetAccommodation
      from.reimbudgetBooth = from.budgetBooth
      from.reimbudgetMeetingroom = from.budgetMeetingroom
      from.reimbudgetConsult = from.budgetConsult
      from.reimbudgetCooperation = from.budgetCooperation
      from.reimbudgetBrandpre = from.budgetBrandpre
      from.reimbudgetFlight = from.budgetFlight
      from.reimbudgetTrain = from.budgetTrain
      from.reimbudgetReception = from.budgetReception
      from.reimbudgetMake = from.budgetMake
      from.reimbudgetDesign = from.budgetDesign
      from.reimbudgetThirdparty = from.budgetThirdparty
      from.reimbudgetTotal = from.budgetTotal
      from.reimbudgetApprovetotal = from.budgetApprovetotal
      from.reimbudgetMealsNum = from.budgetMealsNum
      from.reimbudgetMealsTotal = from.budgetMealsTotal
      from.reimbudgetAccommodationNum = from.budgetAccommodationNum
      from.reimbudgetAccommodationTotal = from.budgetAccommodationTotal
      from.reimbudgetBoothNum = from.budgetBoothNum
      from.reimbudgetBoothTotal = from.budgetBoothTotal
      from.reimbudgetMeetingroomNum = from.budgetMeetingroomNum
      from.reimbudgetMeetingroomTotal = from.budgetMeetingroomTotal
      from.reimbudgetConsultNum = from.budgetConsultNum
      from.reimbudgetConsultTotal = from.budgetConsultTotal
      from.reimbudgetCooperationNum = from.budgetCooperationNum
      from.reimbudgetCooperationTotal = from.budgetCooperationTotal
      from.reimbudgetPreCost = from.budgetPreCost
      from.reimbudgetBrandpreNum = from.budgetBrandpreNum
      from.reimbudgetBrandpreTotal = from.budgetBrandpreTotal
      from.reimbrandpreMoneytotal = from.brandpreMoneytotal
      from.reimbudgetFlightNum = from.budgetFlightNum
      from.reimbudgetFlightTotal = from.budgetFlightTotal
      from.reimbudgetTrainNum = from.budgetTrainNum
      from.reimbudgetTrainTotal = from.budgetTrainTotal
      from.reimbudgetReceptionNum = from.budgetReceptionNum
      from.reimbudgetReceptionTotal = from.budgetReceptionTotal
      from.reimbudgetMakeNum = from.budgetMakeNum
      from.reimbudgetMakeTotal = from.budgetMakeTotal
      from.reimbudgetDesignNum = from.budgetDesignNum
      from.reimbudgetDesignTotal = from.budgetDesignTotal
      from.reimbudgetThirdpartyNum = from.budgetThirdpartyNum
      from.reimbudgetThirdpartyTotal = from.budgetThirdpartyTotal
      from.reimbudgetServiceTotal = from.budgetServiceTotal
      from.reimOtherFee = from.otherFee
      from.reimRegisterFee = from.registerFee
      from.reimOnlineLiveBroadcastingFee = from.onlineLiveBroadcastingFee
      from.reimInfoPubAmount = from.infoPubAmount
      return from
    },

    /**
     * 新增总结单时，讲者信息的咨询费为null
     * @param {Array} speakers
     * @returns {Array} 返回咨询费清零的讲者信息
     */
    clearSpeaker(speakers) {
      for (let item of speakers.speakers) {
        item.appConsultingFee = null
      }
      speakers.budgetConsultTotal = 0

      return speakers
    },

    /**
     * 新增总结单时，推广物料信息的实际数量为null
     * @param {Array} brandpres
     * @returns {Array} 返回咨询费清零的讲者信息
     */
    clearBrandpre(brandpres) {
      for (let item of brandpres.brandpreInfoDTOs) {
        item.brandpreNumApp = null
      }

      brandpres.brandpreMoneytotal = 0
      return brandpres
    },

    /**
     * 新增总结单时，品牌信息实际费用明细为null
     * @param {Array} expense
     * @returns {Array} 返回咨询费清零的讲者信息
     */
    clearExpense(expense) {
      expense.expenseDetailDTOs = []
      expense.infoPubAmount = 0
      return expense
    },

    /**
     * 新增总结单时，必填项为null(专家/讲者的咨询费为0，推广物料费为0)
     * @param {Array} fees
     * @returns {Array} 返回咨询费清零的讲者信息
     */
    clearFeeReimInfo(fees) {
      fees['budgetMeals'] = null
      fees['budgetMealsNum'] = null
      fees['budgetAccommodation'] = null
      fees['budgetAccommodationNum'] = null
      fees['budgetBooth'] = null
      fees['budgetBoothNum'] = null
      fees['budgetMeetingroom'] = null
      fees['budgetMeetingroomNum'] = null
      fees['budgetFlight'] = null
      fees['budgetFlightNum'] = null
      fees['budgetTrain'] = null
      fees['budgetTrainNum'] = null
      fees['budgetReception'] = null
      fees['budgetReceptionNum'] = null
      fees['budgetMake'] = null
      fees['budgetMakeNum'] = null
      fees['budgetDesign'] = null
      fees['budgetDesignNum'] = null
      fees['budgetThirdparty'] = null
      fees['budgetThirdpartyNum'] = null
      fees['budgetServiceTotal'] = null
      fees['onlineLiveBroadcastingFee'] = null
      fees['otherFee'] = null
      fees['registerFee'] = null
      fees['budgetBrandpreTotal'] = null
      fees['budgetConsultTotal'] = 0
      fees['infoPubAmount'] = 0
      return fees
    },

    // 选择打印的回调
    onPrintChange(list) {
      this.options = list
    },

    // 选择打印信息
    handldPrintlick() {
      this.print()
      this.showModal = false
    },

    // 打开申请单详情
    openApp(pk) {
      if (!pk) return this.$message.error('无法获取申请单')
      const http = getCrmHttp() + '/redirect'
      const token = this.$store.getters['token']
      const uid = this.$store.getters['uid']
      const url = `${http}?token=${token}&uid=${uid}&route=academicApplicationForm&pid=${pk}&type=check&todo=true`
      const newWindow = window.open('', '_blank')
      newWindow.location.href = url
    },

    // 学术申请单打印
    print() {
      this.printVisible = true
      this.$nextTick(() => this.$refs['printWrapper'].print())
    },

    isDisabled
  }
}
</script>

<style lang="less" scoped>
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.loading-icon {
  margin-top: 15%;
}
/deep/ .wrapper-style.ant-anchor-wrapper {
  padding-left: 0px;
  margin-left: 0px;
}
/deep/ .wrapper-style .ant-anchor-ink {
  display: none;
}

/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
  &:hover {
    background: #4587f4;
    border-color: #4587f4;
    color: #ffffff;
  }
}

/deep/.ant-btn-primary {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
  &:hover {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
  &:focus {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
}
</style>
