<template>
  <div class="w-full relative">
    <div class="example" v-if="loading">
      <a-spin class="loading-icon" size="large" :tip="tip" />
    </div>

    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      class="button-group py-4"
      style="margin-right: 3.56%"
    >
      <a-col :span="12">
        <span v-if="meetingsTimeStr" style="padding-left: 32px; color: #4587f5">
          {{ meetingsTimeStr }}
        </span>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-radio-group
          :value="value"
          buttonStyle="solid"
          @change="changeButton"
        >
          <a-radio-button value="a" :disabled="isRead" @click="save">
            保存
          </a-radio-button>
          <a-radio-button value="b" :disabled="isRead" @click="submit">
            提交
          </a-radio-button>
          <a-radio-button
            value="c"
            :disabled="isRead || applicationform.id === ''"
            @click="del"
          >
            删除
          </a-radio-button>
        </a-radio-group>
        <a-button
          type="primary"
          class="ml-4"
          :disabled="
            status === '1' ||
            status === '5' ||
            status === '15' ||
            status === '0' ||
            status === '98' ||
            status === '99'
          "
          @click="getBack"
        >
          撤销
        </a-button>

        <a-button
          type="primary"
          style="margin-left: 1.5em; margin-right: 1.5em"
          @click="showModal = true"
        >
          打印申请单
        </a-button>
      </a-col>
    </a-row>

    <div class="mt-4" v-else>
      <a-row>
        <a-col :span="12">
          <span
            v-if="meetingsTimeStr"
            style="padding-left: 32px; color: #4587f5"
          >
            {{ meetingsTimeStr }}
          </span>
        </a-col>
        <a-col :span="12" class="text-right">
          <a-button type="primary" class="mr-4" @click="showModal = true">
            打印申请单
          </a-button>
        </a-col>
      </a-row>
    </div>

    <div class="bill-content" :class="[type === 'waitwork' ? 'wait-bg' : '']">
      <user-info
        :userInfo.sync="userInfo"
        :type="type"
        :status="isRead"
        @username="$emit('username', $event)"
        @updateItem="isUpdate = true"
        @deptCode="chooseDeptCode"
      />
      <activity-info
        :activityInfo.sync="activityInfo"
        :status="isRead"
        :conferenceTypeName="conferenceTypeName"
        :deptCode="deptCode"
        :deptdesc="deptdesc"
        :deptNature="deptNature"
        :appdate="userInfo.appdate"
        @updateItem="isUpdate = true"
        @chooseConferenceTypeName="chooseConferenceTypeName($event)"
        @chooseHostingTypeName="chooseHostingTypeName($event)"
      />
      <activity-type
        v-if="[1, 2, 4].includes(getHostingType)"
        :activityType.sync="activityType"
        :status="isRead"
        :conferenceTypeName="conferenceTypeName"
        :businessType="activityInfo.businessType"
        @updateItem="isUpdate = true"
      />
      <speaker-info
        v-if="[1, 2, 4].includes(getHostingType)"
        :speakerNum="activityInfo.speakerNum"
        :speakerInfo.sync="speakerInfo"
        :status="isRead"
        @updateItem="isUpdate = true"
      />
      <brandpre-info
        v-if="[1, 2, 4].includes(getHostingType)"
        :brandpreInfo.sync="brandpreInfo"
        :status="isRead"
        :conferenceTypeName="conferenceTypeName"
        :isbrandpre="activityInfo.isbrandpre"
        @updateItem="isUpdate = true"
      />
      <ExpenseInfo
        v-if="[3, 4].includes(getHostingType)"
        :expenseInfo.sync="expenseInfo"
        :status="isRead"
        @updateItem="isUpdate = true"
      ></ExpenseInfo>
      <fee-info
        :feeInfo.sync="feeInfo"
        :status="isRead"
        :conferenceTypeName="conferenceTypeName"
        :brandpreMoneytotal="brandpreInfo.brandpreMoneytotal"
        :budgetPreCost="activityInfo.budgetPreCost"
        :activityNatrue="activityInfo.activityNatrue"
        :hasconfContract="activityInfo.hasconfContract"
        :hostingTypeName="hostingTypeName"
        :infoPubAmount="expenseInfo.infoPubAmount"
        :budgetConsultTotal="speakerInfo.budgetConsultTotal"
        @updateItem="isUpdate = true"
      />
      <note
        :note.sync="note"
        :status="isRead"
        @updateItem="isUpdate = true"
      ></note>
      <post-script
        billType="XSSQ"
        :pid="this.pid"
        :uid="applicationform.applicant"
        :status="status"
      ></post-script>
      <oa-process
        v-if="applicationform.flowInfoDTO"
        :status="isRead"
        :summaryid="applicationform.flowInfoDTO.summaryid"
        :nextapproveman="applicationform.flowInfoDTO.nextapproveman"
        :curApprover="applicationform.flowInfoDTO.curApprover"
      />
      <comment :billId="applicationform.id" />
    </div>
    <print-wrapper ref="printWrapper" v-show="printVisible">
      <xssq-print
        :datas="appPrintData"
        :options="options"
        title="学术会议申请单"
      ></xssq-print>
    </print-wrapper>
    <modal
      v-if="showModal"
      title="选择打印的信息"
      @handldClick="handldPrintlick"
      @close="showModal = false"
    >
      <div>
        <a-checkbox-group
          v-model="options"
          name="checkboxgroup"
          :options="plainOptions"
          @change="onPrintChange"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import UserInfo from '@/views/activity/modules/activebill/UserInfo'
import ActivityType from '@/views/activity/modules/activebill/ActivityType'
import ActivityInfo from '@/views/activity/modules/activebill/ActivityInfo'
import SpeakerInfo from '@/views/activity/modules/activebill/SpeakerInfo'
import BrandpreInfo from '@/views/activity/modules/activebill/BrandpreInfo'
import ExpenseInfo from '@/views/activity/modules/activebill/ExpenseInfo'
import FeeInfo from '@/views/activity/modules/activebill/FeeInfo'
import Note from '@/views/activity/modules/activebill/Note'
import PostScript from '@/views/activity/modules/activebill/PostScript'
import OaProcess from '@/views/activity/modules/activebill/OaProcess'
import Comment from '@/views/activity/modules/activebill/Comment'
import PrintWrapper from 'vue-easy-print'
import XssqPrint from '@/views/activity/modules/print/XssqPrint'
import Modal from '@/components/Modal'

import { isDisabled, validator } from '@/utils/util'

import {
  getUserInfo,
  getActivityType,
  getActivityInfo,
  getSpeakerInfo,
  getBrandpreInfo,
  getExpenseInfo,
  getFeeInfo,
  getNote
} from '@/views/activity/modules/util.js'

import {
  getActivityAppById,
  getEditActivityApp,
  getActivityApp,
  getSubmitXSSQ,
  getDeleteActivityApp,
  getOACancel2
} from '@/api/acdemicActive/acdemic-application'

import { getDeleteFiles } from '@/api/acdemicActive/upload'

export default {
  name: 'AcadmeicApplicationTable',
  props: ['type', 'pid'],
  components: {
    UserInfo,
    ActivityType,
    ActivityInfo,
    BrandpreInfo,
    ExpenseInfo,
    FeeInfo,
    Note,
    SpeakerInfo,
    PostScript,
    OaProcess,
    Comment,
    PrintWrapper,
    XssqPrint,
    Modal
  },
  data() {
    return {
      value: 'a',
      loading: false,
      tip: '正在加载...',
      printVisible: false,
      showModal: false, //打印的选择信息
      conferenceTypeName: '', //所属公司名称
      hostingTypeName: '', //举办类型的名称
      deptCode: '', //部门的编码
      deptdesc: 0, // 部门等级
      deptNature: '', // 部门描述
      status: '', //单据状态
      isUpdate: false, //检查单据是否修改
      meetingsTimeStr: '', // 统计信息
      userInfo: {}, //申请人和执行人信息
      activityType: {}, //活动类型及执行标准信息
      activityInfo: {}, //活动信息
      speakerInfo: {}, //讲者信息
      expenseInfo: {}, //品牌信息-信息发布费用明细
      brandpreInfo: {}, //推广物料信息
      feeInfo: {}, //费用信息
      note: {}, //备注
      appPrintData: {}, //申请单打印的数据
      options: ['flow', 'ps', 'comment'],
      plainOptions: [
        { label: '流程信息', value: 'flow' },
        { label: '发起人附言', value: 'ps' },
        { label: '审批意见', value: 'comment' }
      ],
      applicationform: {
        id: '',
        appdate: this.formatDate(new Date()),
        conferenceCode: '',
        conferenceName: '',
        conferenceType: '',
        businessType: '',
        product: '',
        applicant: '',
        post: '',
        contact: '',
        excutor: '',
        excutorPost: '',
        excutorContact: '',
        region: '',
        province: '',
        locality: '',
        department: '',
        activityDepartment: '',
        hostingType: '', //举办类型
        pubTheme: '', //品牌信息-发布主题
        pubPurpose: '', //品牌信息-发布目的
        pubScheme: '', //品牌信息-发布方案
        partnerContract: '', //品牌信息-合作方合同
        infoPubAmount: 0, //品牌信息-信息发布费用
        activityPlace: '',
        activityTheme: '',
        activityPurpose: '',
        activityNatrue: '自办',
        activityStdate: '',
        activityEddate: '',
        speakerNum: null,
        preinvitednum: null,
        internalnum: null,
        participantsList: '',
        activityInvitation: '',
        sponsor: '',
        sponsorEstdate: '',
        amountOfMoney: '',
        sponsorQualification: null,
        sponsorInvitation: null,
        contract: null,
        sponsorUrl: null,
        budgetPreCost: null, //预付金额
        otherContract: '否', // 其他合同
        isinvitedspeaker: '否',
        isbrandpre: '否',
        brandpreMoneytotal: 0, //推广物料-合计
        budgetMeals: null, //餐费单价
        budgetAccommodation: null, //住宿费单价
        budgetBooth: null, //展地费单价
        budgetMeetingroom: null, //会议费单价
        budgetConsult: 0, //咨询费单价
        budgetCooperation: null, //协办费单价
        budgetBrandpre: null, //推广物料费单价
        budgetFlight: null, //飞机费单价
        budgetTrain: null, //高铁费单价
        budgetReception: null, //接送费单价
        budgetMake: null, //制作费单价
        budgetDesign: null, //设计费单价
        budgetThirdparty: null, //第三方会务人员费用单价
        budgetTotal: 0, //合计
        budgetApprovetotal: 0, //批准总预算
        confCompany: null,
        contractMoney: null,
        contractScan: null,
        hasconfContract: null,
        remarks: null,
        budgetMealsNum: null,
        budgetMealsTotal: 0,
        budgetAccommodationNum: null,
        budgetAccommodationTotal: 0,
        budgetBoothNum: null,
        budgetBoothTotal: 0,
        budgetMeetingroomNum: null,
        budgetMeetingroomTotal: 0,
        budgetConsultNum: 0,
        budgetConsultTotal: null,
        budgetCooperationNum: null,
        budgetCooperationTotal: 0,
        budgetBrandpreNum: null,
        budgetBrandpreTotal: 0,
        otherFee: null, // 其他费用
        registerFee: null, // 注册费用
        onlineLiveBroadcastingFee: null, // 线上直播平台费用
        budgetFlightNum: null,
        budgetFlightTotal: 0,
        budgetTrainNum: null,
        budgetTrainTotal: 0,
        budgetReceptionNum: null,
        budgetReceptionTotal: 0,
        budgetMakeNum: null,
        budgetMakeTotal: 0,
        budgetDesignNum: null,
        budgetDesignTotal: 0,
        budgetThirdpartyNum: null,
        budgetThirdpartyTotal: 0,
        budgetServiceTotal: null,
        templateCode: null,
        activitytype: null,
        conferenceTypeDTO: null,
        businessTypeDTO: null,
        activityNatrueDTO: null,
        productDTO: null,
        provinceDTO: null,
        regionDTO: null,
        localityDTO: null,
        departmentDTO: null,
        hostingTypeDTO: null,
        speakers: [],
        brandpreInfoDTOs: [],
        expenseDetailDTOs: [], //品牌信息-信息发布费用明细
        activityTypeStdDTO: {},
        flowInfoDTO: {
          status: '1'
        }
      }
    }
  },
  computed: {
    isRead() {
      return isDisabled(this.status)
    },
    showAvatar() {
      return this.$store.getters['protal']
    },
    getHostingType() {
      if (!this.hostingTypeName) return 1

      let active = false
      let purtive = false
      if (this.hostingTypeName.indexOf('学术推广活动') !== -1) active = true
      if (this.hostingTypeName.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    }
  },
  created() {
    // 查看申请单
    this.loading = true
    if (this.type === 'check' || this.type === 'waitwork') {
      getActivityAppById(this.pid).then(response => {
        this.applicationform = Object.assign(this.applicationform, response)
        this.userInfo = getUserInfo(response)
        this.activityType = getActivityType(response)
        this.activityInfo = getActivityInfo(response)
        this.speakerInfo = getSpeakerInfo(response)
        this.brandpreInfo = getBrandpreInfo(response)
        this.expenseInfo = getExpenseInfo(response)
        this.feeInfo = getFeeInfo(response)
        this.note = getNote(response)
        if (!response.flowInfoDTO) {
          this.status = '1'
        } else {
          this.status = response.flowInfoDTO.status
        }
        if (response.conferenceTypeDTO) {
          this.conferenceTypeName = response.conferenceTypeDTO.name
        }
        if (response.hostingTypeDTO) {
          this.hostingTypeName = response.hostingTypeDTO.name
        }
        if (response.departmentDTO) {
          this.deptCode = response.departmentDTO.deptcode
          this.deptdesc = response.departmentDTO.deptdesc
          this.deptNature = response.departmentDTO.deptNature
        }

        const { meetingsTimeStatistics: m } = this.applicationform
        if (m) {
          this.mTimeStr = `${m.activityTypeName}, ${m.yearMonth}年`
          if (m.areaYearTimes || m.provinceYearTimes || m.localityYearTimes) {
            this.mTimeStr += `${this.form.regionName}, `
            this.mTimeStr += `大区层级共${m.areaYearTimes || '0'}场, `
            this.mTimeStr += `省区层级共${m.provinceYearTimes || '0'}场, `
            this.mTimeStr += `地区层级共${m.localityYearTimes || '0'}场, `
          } else {
            this.mTimeStr += `总部层级共${m.yearTimes || '0'}场，`
          }

          this.mTimeStr += `本次为‘费用承担部门’第${m.deptYearSeq}/${m.deptYearTimes}场`
        }

        this.$emit('status', this.status)
        this.$emit('userInfo', this.userInfo)
        this.appPrintData = this.applicationform
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    } else {
      // 添加申请单
      this.status = this.applicationform.flowInfoDTO.status
      this.userInfo = getUserInfo(this.applicationform)
      this.activityType = getActivityType(this.applicationform)
      this.activityInfo = getActivityInfo(this.applicationform)
      this.speakerInfo = getSpeakerInfo(this.applicationform)
      this.brandpreInfo = getBrandpreInfo(this.applicationform)
      this.expenseInfo = getExpenseInfo(this.applicationform)
      this.feeInfo = getFeeInfo(this.applicationform)
      this.note = getNote(this.applicationform)
      this.appPrintData = this.applicationform
      this.$emit('status', this.applicationform.flowInfoDTO.status)
      setTimeout(() => {
        this.loading = false
      }, 300)
    }
  },
  methods: {
    changeButton() {
      this.value = 'a'
    },

    //将时间类型转换成固定格式的时间字符串
    formatDate(date) {
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      return y + '-' + m + '-' + d
    },

    //选取的会议类型
    chooseConferenceTypeName(value) {
      this.conferenceTypeName = value
    },

    // 选取的举办类型回调
    chooseHostingTypeName(value) {
      this.hostingTypeName = value
    },

    //选取的部门
    chooseDeptCode(value, desc, nature) {
      this.deptCode = value
      this.deptdesc = desc
      this.deptNature = nature
    },

    //保存
    save(callback) {
      this.assgin()
      this.loading = true
      this.tip = '正在保存...'
      if (this.applicationform.id) {
        this.applicationform = this.filterData(this.applicationform)
        getEditActivityApp(this.applicationform)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.loading = false
            this.$emit(this.status)
            if (this.showAvatar) {
              //门户保存crm单据时，直接关闭窗口
              window.close()
            }
            if (!this.isUpdate) {
              //未做更改，点击保存按钮
              this.$router.replace({ name: 'activityApplication' })
            } else {
              // 做更改保存，非点击按钮触发事件
              if (typeof callback === 'function') {
                callback(true)
              } else {
                //点击按钮触发事件
                this.isUpdate = false
                this.$router.replace({ name: 'activityApplication' })
              }
            }
          })
          .catch(() => {
            this.loading = false
            if (typeof callback === 'function') callback(false)
          })
      } else {
        this.applicationform = this.filterData(this.applicationform)
        getActivityApp(this.applicationform)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.loading = false
            this.$emit(this.status)
            if (this.showAvatar) {
              //门户保存crm单据时，直接关闭窗口
              window.close()
            }
            if (!this.isUpdate) {
              //未做更改，点击保存按钮
              this.$router.replace({ name: 'activityApplication' })
            } else {
              // 做更改保存，非点击按钮触发事件
              if (typeof callback === 'function') {
                callback(true)
              } else {
                //点击按钮触发事件
                this.isUpdate = false
                this.$router.replace({ name: 'activityApplication' })
              }
            }
          })
          .catch(() => {
            this.loading = false
            if (typeof callback === 'function') callback(false)
          })
      }
    },

    //提交
    submit() {
      this.assgin()
      this.isUpdate = false
      if (validator(this.applicationform)) {
        this.loading = true
        this.tip = '正在保存...'
        this.applicationform = this.filterData(this.applicationform)
        getSubmitXSSQ(this.applicationform)
          .then(() => {
            this.delUploadFile()
            this.$message.success('提交成功')
            this.loading = false
            if (this.showAvatar) {
              window.close()
            }
            this.$router.replace({ name: 'activityApplication' })
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    //删除
    del() {
      this.isUpdate = false
      this.$confirm({
        title: '是否要删除该学术推广活动申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getDeleteActivityApp(this.applicationform.id).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) {
              window.close()
            }
            this.$router.replace({ name: 'activityApplication' })
          })
        },
        onCancel: () => {}
      })
    },

    // 撤销
    getBack() {
      this.$confirm({
        title: '是否要撤销该学术活动申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.applicationform.id,
            'XSSQ',
            this.applicationform.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.status = '5'
            this.feeInfo.budgetApprovetotal = 0 //批准总预算为0
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    // 提交数据时，跟根据举办类型初始化不同类型的数据
    filterData(apply) {
      const fileIds = []

      if ([4].includes(this.getHostingType)) return apply

      if ([1, 2].includes(this.getHostingType)) {
        apply.pubTheme = ''
        apply.pubPurpose = ''
        apply.infoPubAmount = 0
        apply.expenseDetailDTOs = []

        if (apply.pubScheme) {
          const pubScheme = JSON.parse(apply.pubScheme)
          pubScheme.forEach(item => {
            fileIds.push(item.id)
          })
          apply.pubScheme = ''
        }
        if (apply.partnerContract) {
          const partnerContract = JSON.parse(apply.partnerContract)
          partnerContract.forEach(item => {
            fileIds.push(item.id)
          })
          apply.partnerContract = ''
        }
        if (
          apply.hasconfContract === '否' &&
          apply.otherContract === '否' &&
          apply.contractScan
        ) {
          const contractScan = JSON.parse(apply.contractScan)
          contractScan.forEach(item => {
            fileIds.push(item.id)
          })
          apply.contractScan = ''
        }
      }

      if ([3].includes(this.getHostingType)) {
        apply.activityPlace = ''
        apply.activityTheme = ''
        apply.activityPurpose = ''
        apply.speakerNum = null
        apply.preinvitednum = null
        apply.internalnum = null
        apply.hasconfContract = ''
        apply.otherContract = '否'
        apply.confCompany = ''
        apply.activityNatrue = '自办'
        apply.contractMoney = ''
        apply.contractScan = ''
        apply.sponsor = '' //主办方名称
        apply.sponsorEstdate = ''
        apply.isinvitedspeaker = ''
        apply.isbrandpre = ''
        apply.budgetConsultTotal = 0
        apply.budgetCooperationTotal = 0
        apply.budgetBrandpreTotal = 0
        apply.speakers = []
        apply.brandpreInfoDTOs = []

        if (apply.sponsorQualification) {
          const sponsorQualification = JSON.parse(apply.sponsorQualification)
          sponsorQualification.forEach(item => {
            fileIds.push(item.id)
          })
          apply.sponsorQualification = ''
        }
        if (apply.contractScan) {
          const contractScan = JSON.parse(apply.contractScan)
          contractScan.forEach(item => {
            fileIds.push(item.id)
          })
          apply.contractScan = ''
        }
        if (apply.sponsorInvitation) {
          const sponsorInvitation = JSON.parse(apply.sponsorInvitation)
          sponsorInvitation.forEach(item => {
            fileIds.push(item.id)
          })
          apply.sponsorInvitation = ''
        }
        if (apply.contract) {
          const contract = JSON.parse(apply.contract)
          contract.forEach(item => {
            fileIds.push(item.id)
          })
          apply.contract = ''
        }
        if (apply.participantsList) {
          const participantsList = JSON.parse(apply.participantsList)
          participantsList.forEach(item => {
            fileIds.push(item.id)
          })
          apply.participantsList = ''
        }
        if (apply.activityInvitation) {
          const activityInvitation = JSON.parse(apply.activityInvitation)
          activityInvitation.forEach(item => {
            fileIds.push(item.id)
          })
          apply.activityInvitation = ''
        }
      }

      if (fileIds.length) {
        getDeleteFiles(fileIds)
      }

      return apply
    },

    //合并单据信息
    assgin() {
      Object.assign(
        this.applicationform,
        this.userInfo,
        this.activityType,
        this.activityInfo,
        this.speakerInfo,
        this.brandpreInfo,
        this.expenseInfo,
        this.feeInfo,
        this.note
      )
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    },

    // 选择打印的回调
    onPrintChange(list) {
      this.options = list
    },

    // 选择打印信息
    handldPrintlick() {
      this.print()
      this.showModal = false
    },

    // 学术申请单打印
    print() {
      this.printVisible = true
      this.$nextTick(() => this.$refs['printWrapper'].print())
    },

    isDisabled
  }
}
</script>

<style lang="less" scoped>
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.loading-icon {
  margin-top: 15%;
}

/deep/ .wrapper-style.ant-anchor-wrapper {
  padding-left: 0px;
  margin-left: 0px;
}
/deep/ .wrapper-style .ant-anchor-ink {
  display: none;
}

/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
  &:hover {
    background: #4587f4;
    border-color: #4587f4;
    color: #ffffff;
  }
}
/deep/.ant-btn-primary {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
  &:hover {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
  &:focus {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
}

// .wait-bg {
//   background-color: #f5f5f5;
// }
</style>
