/* eslint-disable prettier/prettier */
import { getDoc } from '@/configs/doc-config'
import { 
  activityNatrueEnum, 
  hasconfContractEnum, 
  whetherDygProjEnum,
  whetherHeadQuartersProjEnum, 
  perferWhetherDygProj 
} from '@/shared/activity'
import moment from 'moment'

export default {
  data() {
    return {
      showMap: false, // 活动地点地图定位
      // 举办性质枚举类型
      activityNatrueEnum,
      // 是否需要会务公司
      hasconfContractEnum,
      // 是否为走进东阳光项目
      whetherDygProjEnum,
      // 是否为总部统一筹划项目
      whetherHeadQuartersProjEnum,
      // 判断显示是否走进东阳光项目的字段
      perferWhetherDygProj,

      businessTypeList: [], // 业务线枚举
      productList: [], // 产品枚举
      activityFormList: [], // 举办形式

      activity: {
        conferenceName: '', // 会议名称
        conferenceCode: '', // 会议编码
        businessType: '', // 业务线（枚举）
        product: '', // 产品（枚举）
        activityForm: '', // 举办形式
        activityNatrue: '', // 举办性质(自办/协办/自办+协办)
        activityTheme: '', // 活动主题
        activityPlace: '', // 活动地点
        latitude: '',  // 活动地点经纬度
        longitude: '', // 活动地点经纬度
        activityStdate: '', // 活动开始日期
        activityEddate: '', // 活动结束日期
        preinvitednum: '', // 预邀参会客户数
        speakerNum: '', // 讲者人数
        accompPersonNum: '',
        internalnum: '', // 内部人员人数
        whetherDygProj: '', // 是否为走进东阳光项目
        /**控制区 */
        sponsor: '', // 主办方名称(协办显示)
        sponsorEstdate: '', // 主办方成立日期(协办显示)
        cooperateContractMny: '',// 协办合同金额(协办显示)
        onlineLiveBroadcastingFee: '', // 线上直播平台服务报价金额(线上显示)
        hasconfContract: '', // 是否需要会务公司（是/否）
        confCompany: '', // 会议公司名称(需要会务公司显示)
        contractMoney: '', // 会务合同金额(需要会务公司显示)
        whetherHeadQuartersProj: '', // 是否为总部统一筹划项目
        headQuartersProjectId: '', // 总部项目ID
        headQuartersProjectName: '', // 总部项目名称
        budgetPreCost: '', // 公对公预付金额
        /**附件区 */
        participantsList: '', // 预邀客户名单
        activityInvitation: '', // 邀请函/会议议程
        sponsorQualification: '', // 主办方资质证明(协办显示)
        sponsorInvitation: '', // 主办方邀请函/招商函(协办显示)
        contract: '', // 协办合同(协办显示)
        onlineStreamingPlatformAgreement: '',// 线上直播平台服务协议/报价单(线上显示)
        contractScan: '', // 会务合同(需要会务公司显示)

        /**多文字区 */
        activityBackGround: '', // 活动背景描述
        activityPurpose: '', // 活动目的
        activityOverview: '',// 活动内容概述
        activityExpectEffect: '' // 活动预期效果
      },

      activityForm: [
        { label: '会议名称', prop: 'conferenceName', disabled: true },
        { label: '会议编码', prop: 'conferenceCode', disabled: true },
        { label: '业务线', prop: 'businessType', disabled: false, type: 'select', options: [] },
        { label: '产品', prop: 'product', disabled: false, type: 'select', options: []  },
        { label: '举办形式', prop: 'activityForm', disabled: false, type: 'select', options: [] },
        { label: '举办性质', prop: 'activityNatrue', disabled: false, type: 'select', options: [] },
        { label: '活动主题', prop: 'activityTheme', disabled: false },
        { label: '活动地点', prop: 'activityPlace', disabled: false },
        { label: '活动开始日期', prop: 'activityStdate', type: 'date', disabled: false, disabledDate: () => this.disabledStDate },
        { label: '活动结束日期', prop: 'activityEddate', type: 'date', disabled: false, disabledDate: () => this.disabledEndDate },
        { label: '预邀参会客户数(不含讲者)', prop: 'preinvitednum', disabled: false, validate: 'number' },
        { label: '讲者人数', prop: 'speakerNum', disabled: false, validate: 'number' },
        { label: '内部人员数', prop: 'accompPersonNum', disabled: false, validate: 'number' }, // 原字段名称陪同人员人数，改为内部人员数
        // { label: '内部人员人数', prop: 'internalnum', disabled: false, validate: 'number' },
        { label: '是否为走进东阳光项目', type: 'select', prop: 'whetherDygProj', disabled: false, options: [], show: () => this.isShowDygProj },
        { label: '主办方名称(协办显示)', prop: 'sponsor', disabled: false, show: () => this.isShowCo },
        { label: '主办方成立日期(协办显示)', prop: 'sponsorEstdate', type: 'date', disabled: false, disabledDate: () => {}, show: () => this.isShowCo },
        { label: '协办合同金额(协办显示)', prop: 'cooperateContractMny', validate: 'digit', disabled: false, show: () => this.isShowCo },
        { label: '线上直播平台服务报价金额(线上显示)', prop: 'onlineLiveBroadcastingFee', validate: 'digit', disabled: false, show: () => this.isShowOnline },
        { label: '是否需要会务公司', prop: 'hasconfContract', disabled: false, type: 'select', options: [] },
        { label: '会议公司名称(需要会务公司显示)', prop: 'confCompany', disabled: false, show: () => this.isShowContract },
        { label: '会务合同金额(需要会务公司显示)', prop: 'contractMoney', disabled: false, validate: 'digit', show: () => this.isShowContract },
        { label: '是否为总部统一筹划项目', prop: 'whetherHeadQuartersProj', disabled: false, type: 'select', options: [] },
        { label: '总部项目名称', prop: 'headQuartersProjectId', type: "select", options: [], disabled: false, show: () => this.isShowWHQProj },
        { label: '公对公预付金额', prop: 'budgetPreCost', disabled: false, validate: 'digit' }
      ],

      activityFileForm: [
        { label: '预邀客户名单', prop: 'participantsList', disabled: false },
        { label: '邀请函/会议议程', prop: 'activityInvitation', disabled: false},
        { label: '主办方资质证明', prop: 'sponsorQualification', disabled: true, show: () => this.isShowCo  },
        { label: '主办方邀请函/招商函', prop: 'sponsorInvitation', disabled: true, show: () => this.isShowCo  },
        { label: '协办合同', prop: 'contract', disabled: true, show: () => this.isShowCo  },
        { label: '线上直播平台服务协议/报价单', prop: 'onlineStreamingPlatformAgreement', notVerity: true, disabled: true, show: () => this.isShowOnline  },
        { label: '会务合同', prop: 'contractScan', disabled: true, show: () => this.isShowContract },
      ],

      activityTextareaForm: [
        { label: '活动背景描述', prop: 'activityBackGround', disabled: false },
        { label: '活动目的', prop: 'activityPurpose', disabled: false},
        { label: '活动内容概述', prop: 'activityOverview', disabled: false },
        { label: '活动预期效果', prop: 'activityExpectEffect', disabled: false}
      ]
    }
  },

  computed: {
    // 直播显示
    isShowOnline() {
      if(!this.activity.activityForm) return false

      const target = this.activityForm[4].options.find(item => item.value === this.activity.activityForm)
      if(!target) return false

      const text = target.text 
      return text.indexOf('+直播') > -1
    },
    // 协办显示
    isShowCo() {
      return this.activity.activityNatrue.indexOf('协办') > -1
    },
    // 会务公司显示
    isShowContract() {
      return this.activity.hasconfContract === '是'
    },
    // 是否为总部统一筹划项目
    isShowWHQProj() {
      return this.activity.whetherHeadQuartersProj === '是'
    },
    // 是否为走进东阳光项目
    isShowDygProj() {
      const { activitytype = '', activityTypeStdDTO = {} } = this.standard
      const { acttype = '' } = activityTypeStdDTO

      let show = false
      if(activitytype && acttype) {
        for (const activityName of this.perferWhetherDygProj) {
          if(acttype.indexOf(activityName) > -1) {
            show = true
            break
          }
        }
      }
      
      return show
    }
  },

  created() {
    // 业务线
    getDoc('200', res => {
      this.businessTypeList = res
      this.activityForm[2].options = res
    })
    // 产品
    getDoc('300', res =>{ 
      this.productList = res
      this.activityForm[3].options = res 
    })
    // 举办形式
    getDoc('053', res => { 
      this.activityFormList = res
      this.activityForm[4].options = res 
    })

    // 总部项目名称
    getDoc('070', res => { 
      this.activityForm[22].options = res 
    })

    // 举办性质
    this.activityForm[5].options = this.activityNatrueEnum
    // 是否为走进东阳光项目
    this.activityForm[13].options = this.whetherDygProjEnum
    // 是否需要会务公司
    this.activityForm[18].options = this.hasconfContractEnum
    // 是否为总部统一筹划项目
    this.activityForm[21].options = this.whetherHeadQuartersProjEnum
  },

  methods: {
    moment,

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
     disabledStDate(time) {
      return (
        time && time >= moment(this.activity.activityEddate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.activity.activityStdate).add(-1, 'day').endOf('day')
      )
    },

    onDateChange([, dateString], item){
      this.activity[item.prop] = dateString
    },

    // 活动信息从地图选取的信息
    getActivityPlace(info) {
      const { address, position } = info
      this.activity.activityPlace = address
      this.activity.latitude = position.lat
      this.activity.longitude = position.lng
    }
  }
}
