<template>
  <a-modal
    :title="`${feeItem.title}发票`"
    width="85%"
    :visible="visible"
    :dialogStyle="{ top: '48px' }"
    @ok="handleOk"
    @cancel="$emit('close')"
  >
    <div class="w-full relative" style="height: calc(100vh - 256px)">
      <div class="my-2">
        <a-button v-if="!isRead" @click="openInvioceChoose">选择票据</a-button>
        <!-- 不是添加总结单和只有交通费显示 -->
        <a-button
          v-if="
            $route.query.type !== 'add' &&
            feeProjectEmunItem.text === '交通费' &&
            billInfos.length
          "
          class="ml-2"
          @click="exportInvioce"
        >
          导出发票
        </a-button>
      </div>
      <a-table
        size="small"
        rowKey="pk"
        :dataSource="billInfos"
        :columns="columns"
        :pagination="false"
        :scroll="{ x: 3600, y: 480 }"
      >
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="checkRender" slot-scope="text, record">
          <span :class="[!!record.checkIn ? 'text-green-600' : 'text-red-600']">
            {{ !!record.checkIn ? '是' : '否' }}
          </span>
        </template>
        <template slot="sourceRender" slot-scope="text">
          <span :class="getSourceColor(text)">
            {{ getSourceLabel(text) }}
          </span>
        </template>
        <template slot="statusRender" slot-scope="text, record">
          <span
            :class="[
              getRepeatConfig(text, 'color'),
              text == 1 && record.exceptions
                ? ' hover:text-blue-600 cursor-pointer'
                : ''
            ]"
            @click="showRepeatInvoice(text, record)"
          >
            {{ getRepeatConfig(text, 'label') }}
          </span>
        </template>
        <template slot="operatRender" slot-scope="text, record">
          <a-button
            title="查看票据"
            type="link"
            size="small"
            icon="eye"
            @click="showInvoiceDetail(record)"
          ></a-button>

          <a-popconfirm
            overlayClassName="popover"
            title="是否删除当前票据?"
            ok-text="是"
            cancel-text="否"
            @confirm="delItem(record)"
          >
            <a-button
              v-if="!isRead"
              title="删除"
              type="link"
              class="text-red-500"
              size="small"
              icon="delete"
            ></a-button>
          </a-popconfirm>
        </template>
        <template slot="footer" slot-scope="currentPageData">
          <div class="text-right">
            <p class="text-xs text-gray-600 font-bold">
              发票金额合计:
              <span class="text-red-500 px-2">
                {{
                  currentPageData.reduce((aval, cval) => {
                    return $np.plus(Number(aval), Number(cval.total))
                  }, 0)
                }}
              </span>
              元
            </p>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 选择票据 -->
    <a-modal
      v-model="showChooseVisible"
      title="请选择票据"
      width="90%"
      :bodyStyle="{ padding: '12px' }"
      :dialogStyle="{ top: '48px' }"
      @ok="chooseInvoice"
    >
      <a-table
        size="small"
        rowKey="pk"
        :dataSource="datas"
        :columns="getColumns"
        :pagination="false"
        :scroll="{ x: 3600 }"
        :rowClassName="
          record => {
            return allFeeItemInviocesPk.includes(record.pk)
              ? ' bg-gray-200 cursor-not-allowed'
              : ''
          }
        "
        :row-selection="{
          getCheckboxProps,
          columnWidth: 36,
          selectedRowKeys,
          onSelect,
          onSelectAll
        }"
      >
        <template slot="sourceRender" slot-scope="text">
          <span :class="[getSourceColor(text)]">
            {{ getSourceLabel(text) }}
          </span>
        </template>
        <template slot="checkTrue" slot-scope="text">
          <span
            :class="[
              text === 'Y'
                ? ' text-green-500'
                : text === 'N'
                ? 'text-red-500'
                : 'text-gray-500'
            ]"
          >
            {{ text === 'Y' ? '真' : text === 'N' ? '假' : '暂不支持验真' }}
          </span>
        </template>
        <template slot="statusRender" slot-scope="text">
          <span :class="[getRepeatConfig(text, 'color')]">
            {{ getRepeatConfig(text, 'label') }}
          </span>
        </template>
        <template slot="operatRender" slot-scope="text, record">
          <span class="relative">
            <a-button
              title="查看票据"
              type="link"
              size="small"
              icon="eye"
              @click="showInvoiceDetail(record)"
            ></a-button>
            <a-avatar
              v-if="allFeeItemInviocesPk.includes(record.pk)"
              shape="square"
              title="已被其他费用项引用"
              :size="18"
              :style="{
                backgroundColor: '#2edfa3',
                verticalAlign: 'middle',
                right: '-8px',
                top: '-16px'
              }"
            >
              引
            </a-avatar>
          </span>
        </template>
      </a-table>
      <a-pagination
        showSizeChanger
        showQuickJumper
        class="text-right mt-4"
        :current="current"
        :pageSize="pageSize"
        :total="total"
        :show-total="total => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="showSizeChange"
      />
    </a-modal>

    <!-- 票据明细预览 -->
    <a-modal
      v-model="invoicVisible"
      title="票据详情"
      width="1024px"
      :zIndex="9999"
      style="top: 32px"
      :maskClosable="false"
      :dialogStyle="{ top: '24px' }"
      :footer="false"
      :bodyStyle="{
        padding: '12px',
        height: 'calc(100vh - 124px)',
        overflow: 'auto'
      }"
    >
      <invoice-preview :form="invoiceForm"></invoice-preview>
    </a-modal>
  </a-modal>
</template>

<script>
import { default as pullAll } from 'lodash/pullAll'
import { default as pullAllBy } from 'lodash/pullAllBy'
import {
  convertKey,
  DUP_OPTIONS,
  SOURCE_OPTIONS
} from '@/configs/invioce-config'
import { getInvoiceList } from '@/api/acdemicActive/acdemic-payment'
import { getInvoiceExport } from '@/api/acdemicActive/acdemic-expense'
import InvoicePreview from '@/views/activity/modules/expense/InvoicePreview.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'InvioceModal',
  props: {
    status: {
      type: Boolean
    },
    pid: {
      type: String,
      default: ''
    },
    // 当前费用信息
    feeItem: {
      type: Object,
      default: () => ({})
    },
    // 当前费用的枚举项
    feeProjectEmunItem: {
      type: Object,
      default: () => ({})
    },
    // 当前费用项的发票信息
    feeItemInvioces: {
      type: Array,
      default: () => []
    },
    // 所有费用项的发票信息
    cooptBillInfos: {
      type: Array,
      default: () => []
    }
  },
  components: {
    InvoicePreview
  },
  data() {
    return {
      invoicVisible: false, // 查看票据详情
      showChooseVisible: false, // 选择票据弹窗
      visible: true,

      current: 1,
      pageSize: 10,
      total: 0,
      datas: [], // 所有可以选择的票据信息

      sourceOptions: SOURCE_OPTIONS,
      dupOptions: DUP_OPTIONS,

      invoiceForm: {}, // 发票的明细
      selectedRowKeys: [], // 选择的列表key
      selectedRows: [], // 选择的列表
      billInfos: [], // 票据列表

      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          align: 'center',
          fixed: 'left',
          width: 48,
          scopedSlots: {
            customRender: 'index'
          }
        },
        {
          title: '是否重复',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          fixed: 'left',
          scopedSlots: {
            customRender: 'statusRender'
          }
        },
        {
          title: '是否签到',
          dataIndex: 'checkIn',
          ellipsis: true,
          scopedSlots: {
            customRender: 'checkRender'
          }
        },
        {
          title: '乘客姓名',
          dataIndex: 'passengers',
          ellipsis: true
        },
        {
          title: '座位类型',
          dataIndex: 'seat',
          ellipsis: true
        },
        {
          title: '金额',
          dataIndex: 'total',
          ellipsis: true
        },
        {
          title: '验真结果',
          dataIndex: 'isCheckTrue',
          align: 'center',
          scopedSlots: {
            customRender: 'checkTrue'
          }
        },
        {
          title: '发票号码',
          dataIndex: 'nnumber',
          ellipsis: true
        },
        {
          title: '发票代码',
          dataIndex: 'code',
          ellipsis: true
        },
        {
          title: '来源方式',
          dataIndex: 'source',
          ellipsis: true,
          scopedSlots: {
            customRender: 'sourceRender'
          }
        },
        {
          title: '开票日期',
          dataIndex: 'ddate',
          ellipsis: true
        },
        {
          title: '类别',
          dataIndex: 'kind',
          ellipsis: true
        },
        {
          title: '发票类型',
          dataIndex: 'typename',
          ellipsis: true
        },
        {
          title: '购买方名称',
          dataIndex: 'buyer',
          ellipsis: true
        },
        {
          title: '购买方税号',
          dataIndex: 'buyerTaxId',
          ellipsis: true
        },
        {
          title: '销售方名称',
          dataIndex: 'seller',
          ellipsis: true
        },
        {
          title: '销售方税号',
          dataIndex: 'sellerTaxId',
          ellipsis: true
        },
        {
          title: '车次号',
          dataIndex: 'trainNumber',
          ellipsis: true
        },
        {
          title: '出发日期',
          dataIndex: 'fromDate',
          ellipsis: true
        },
        {
          title: '出发城市',
          dataIndex: 'fromCity',
          ellipsis: true
        },
        {
          title: '到达城市',
          dataIndex: 'toCity',
          ellipsis: true
        },
        {
          title: '舱位等级',
          dataIndex: 'className',
          ellipsis: true
        },
        {
          title: '航班号',
          dataIndex: 'flightNumber',
          ellipsis: true
        },
        {
          title: '税前金额',
          dataIndex: 'pretaxAmount',
          ellipsis: true
        },

        {
          title: '税额',
          dataIndex: 'tax',
          ellipsis: true
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {
            customRender: 'operatRender'
          }
        }
      ]
    }
  },

  computed: {
    isRead() {
      return this.status
    },

    getColumns() {
      if (this.feeProjectEmunItem.text !== '交通费') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.columns.splice(1, 3)
      }
      return this.columns
    },

    // 不包含已选择的当前费用的信息
    allFeeItemInviocesPk() {
      return this.cooptBillInfos
        .filter(item => !this.selectedRowKeys.includes(item.pk))
        .map(i => i.pk)
    }
  },

  created() {
    this.selectedRowKeys = this.feeItemInvioces.map(item => item.pk)
    this.selectedRows = cloneDeep(this.feeItemInvioces)
    this.billInfos = cloneDeep(this.feeItemInvioces)

    this.getList()
  },

  watch: {
    isRead() {
      if (!this.isRead && !this.datas.lenght) this.getList()
    }
  },

  methods: {
    handleOk() {
      this.$emit('chooseInvioces', this.selectedRows)
      this.$emit('close')
    },

    pageChange(current) {
      this.current = current
      this.getList()
    },

    showSizeChange(current, pageSize) {
      this.pageSize = pageSize
      this.getList()
    },

    /**
     * 获取发票来源方式中文
     */
    getSourceLabel(val) {
      const item = this.sourceOptions.find(i => i.value === val)
      return item ? item.label : val
    },
    /**
     * 获取发票来源方式字色
     */
    getSourceColor(val) {
      const item = this.sourceOptions.find(i => i.value === val)
      return item ? item.color : val
    },
    /**
     * 获取重复票的 label 和 底纹色
     */
    getRepeatConfig(val, prop) {
      const item = this.dupOptions.find(i => i.value === val)
      return item ? item[prop] : ''
    },

    onSelect(record, selected) {
      if (selected) {
        this.selectedRowKeys.push(record.pk)
        this.selectedRows.push(record)
      } else {
        this.selectedRowKeys = [...pullAll(this.selectedRowKeys, [record.pk])]
        this.selectedRows = [...pullAllBy(this.selectedRows, [record], 'pk')]
      }
    },

    onSelectAll(selected, selectedRows, changeRows) {
      const pkList = changeRows.map(item => item.pk)
      if (selected) {
        this.selectedRowKeys = [...this.selectedRowKeys, ...pkList]
        this.selectedRows = [...this.selectedRows, ...changeRows]
      } else {
        this.selectedRowKeys = [...pullAll(this.selectedRowKeys, [...pkList])]
        this.selectedRows = [
          ...pullAllBy(this.selectedRows, [...changeRows], 'pk')
        ]
      }
    },

    // 确定选择的票据信息
    chooseInvoice() {
      const nnumbers = this.selectedRows
        .filter(item => item.isCheckTrue === 'N')
        .map(item => item.nnumber)

      if (nnumbers.length > 0) {
        this.$message.warning(
          `发票号码为【${nnumbers.join('、')}】的票据验真为假票，不可进行报销。`
        )

        return
      }

      this.billInfos = this.selectedRows
      this.showChooseVisible = false
    },

    // 删除某项
    delItem(record) {
      this.selectedRowKeys = pullAll(this.selectedRowKeys, [record.pk])
      this.billInfos = this.billInfos.filter(item => item.pk !== record.pk)
      this.selectedRows = this.selectedRows.filter(
        item => item.pk !== record.pk
      )
      this.$emit('removeCooptBillInfos', record.pk)
    },

    // 打开票据选择界面
    openInvioceChoose() {
      this.showChooseVisible = true
    },

    // 查看票据明细
    showInvoiceDetail(record) {
      this.invoiceForm = record
      this.invoicVisible = true
    },

    // 选择发票
    getCheckboxProps(record) {
      return {
        props: {
          disabled: this.allFeeItemInviocesPk.includes(record.pk)
        }
      }
    },

    exportInvioce() {
      getInvoiceExport(this.pid, this.feeProjectEmunItem.value).then(() => {
        this.$message.success('导出成功')
      })
    },

    /**
     * 获取票据的列表信息
     */
    getList() {
      if (!this.isRead) {
        getInvoiceList(this.current, this.pageSize).then(res => {
          this.datas = convertKey(res.datas)
          this.total = res.totalNum
        })
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
