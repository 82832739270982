import request from '@/utils/request'

export const dicttypecolumns = [
  {
    title: '编码',
    width: 200,
    dataIndex: 'code'
  },
  {
    title: '名称',
    width: 300,
    dataIndex: 'name'
  },
  {
    title: '操作',
    width: 150,
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export const dictcolumns = [
  {
    title: '编码',
    width: 400,
    dataIndex: 'code'
  },
  {
    title: '名称',
    width: 400,
    dataIndex: 'name'
  },
  {
    title: '排序',
    width: 100,
    dataIndex: 'sort'
  },
  {
    title: '是否启用',
    width: 100,
    dataIndex: 'enable',
    scopedSlots: { customRender: 'enable' }
  },
  {
    title: '操作',
    dataIndex: 'id',
    width: 150,
    scopedSlots: { customRender: 'action' }
  }
]

//加载字典类型列表
export function loadDictTypeTable(page, params) {
  return request({
    url: `/defDocList/${page.current}/${page.pageSize}`,
    method: 'get',
    params: {
      ...params
    }
  })
}

//加载字典类型(不分页)
export function loadDictTypes(params) {
  return request({
    url: `/defDocList`,
    method: 'get',
    params: {
      ...params
    }
  })
}

//加载字典列表
export function loadDictTable(params) {
  return request({
    url: `/defDoc/selectDefDocListByHID`,
    method: 'get',
    params: {
      ...params
    }
  })
}
//新增字典类型
export function saveDictType(data) {
  return request({
    url: `/defDocList`,
    method: 'post',
    data: data
  })
}

//新增字典
export function saveDict(data) {
  return request({
    url: `/defDoc`,
    method: 'post',
    data: data
  })
}

//更新字典类型
export function updateDictType(dicttypeid, data) {
  return request({
    url: `/defDocList/${dicttypeid}`,
    method: 'put',
    data: data
  })
}

//更新字典
export function updateDict(dictid, data) {
  return request({
    url: `/defDoc/${dictid}`,
    method: 'put',
    data: data
  })
}

//删除字典
export function deleteDict(dictid, data) {
  return request({
    url: `/defDoc/${dictid}`,
    method: 'delete',
    data: data
  })
}

//字典类型详情
export function loadDictTypeInfo(dicttypeid, params) {
  return request({
    url: `/defDocList/${dicttypeid}`,
    method: 'get',
    params: {
      ...params
    }
  })
}

//字典详情
export function loadDictInfo(dictid, params) {
  return request({
    url: `/defDoc/${dictid}`,
    method: 'get',
    params: {
      ...params
    }
  })
}
