<template>
  <div>
    <a-modal
      bordered
      title="选择活动类型及执行标准"
      width="1000px"
      class="active-select-modal"
      v-model="visible"
      :keyboard="false"
      @ok="handleOk"
    >
      <div class="w-full">
        <a-table
          bordered
          rowKey="actID"
          :pagination="false"
          :columns="columns"
          :centered="true"
          :dataSource="activeTypeList"
          :loading="loading"
          :scroll="{ y: 480 }"
          @change="getList"
          :rowSelection="{
            onChange: onSelectChange,
            type: 'radio',
            selectedRowKeys: selectedRowKeys
          }"
          :rowClassName="
            (record, index) => {
              return index % 2 === 1 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    sclick(record)
                  }
                }
              }
            }
          "
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column
            }"
            class="custom-filter-dropdown"
          >
            <a-input
              v-ant-ref="c => (searchInput = c)"
              placeholder="查询"
              :value="selectedKeys[0]"
              @change="
                e => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
              style="width: 180px; margin-bottom: 8px; display: block"
            />
            <a-button
              type="primary"
              @click="() => handleSearch(selectedKeys, confirm, column)"
              icon="search"
              style="width: 80px; margin-right: 10px"
            >
              查询
            </a-button>
            <a-button
              @click="() => handleReset(clearFilters, column)"
              style="width: 80px"
            >
              重置
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { loadTableData } from '@/api/acdemicActive/activity-types'

export default {
  name: 'chooseActiveModal',
  props: {
    conferenceType: { type: String }
  },
  data() {
    return {
      visible: false,
      loading: false,
      current: 1,
      pageSize: 200,
      total: 0,
      conditions: {},
      conferenceTypeId: '',
      activeTypeList: [],
      selectedRowKeys: [], //选择的行id
      activeType: '',
      columns: [
        {
          title: '活动类型',
          dataIndex: 'acttype',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        }
      ]
    }
  },

  watch: {
    conferenceType(val) {
      if (!val) return
      this.conferenceTypeId = val
      this.getList()
    }
  },

  methods: {
    handleOk() {
      this.visible = false
      const activeType = this.activeTypeList.find(
        item => item.actID === this.activeType
      )
      if (activeType) {
        // 解决model闪烁的问题
        setTimeout(() => this.$emit('activeType', activeType), 500)
      }
    },

    // 按条件查询
    handleSearch(selectedKeys, confirm, column) {
      if (selectedKeys[0]) {
        this.conditions[column.dataIndex] = selectedKeys[0]
      } else {
        delete this.conditions[column.dataIndex]
      }

      confirm()
    },

    // 重置
    handleReset(clearFilters, column) {
      delete this.conditions[column.dataIndex]
      clearFilters()
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的活动类型及执行标准
     */
    sclick(item) {
      const activeType = []
      activeType.push(item.actID)
      this.onSelectChange(activeType)
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.activeType = selectedRowKeys[0]
    },

    getList() {
      const conditions = {
        current: this.current,
        pageSize: this.pageSize,
        onlyEnable: true,
        stdtype: this.conferenceTypeId
      }

      loadTableData(conditions, this.conditions).then(res => {
        this.activeTypeList = res.datas
      })
    }
  }
}
</script>

<style lang="less">
.active-select-modal .ant-modal-body {
  padding: 8px 16px;
}
</style>
