<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">丙肝送检公费申请单详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :disabled="isFlow"
        :loading="dataLoading"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'hcvApplication' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
        @select-change="onUserChange"
      ></base-form-items>

      <div class="text-orange-500" v-if="!getCommonDisabled">
        <p>
          <span class="ml-4">
            <a-icon class="inline-block" type="exclamation-circle" />
          </span>
          说明:
          部门是个虚拟字段，只在填单时选择大区、省办使用（针对有兼职部门的人），对单据不造成任何影响。
        </p>
      </div>

      <base-form-title title="核销基本信息" icon="book" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
        :formTextAreaList="formBaseTextAreaList"
        @select-change="selectChange"
      ></base-form-items>

      <base-form-title title="预送检信息" icon="container" />
      <base-form-items
        :formData="form"
        :formBaseList="formTestList"
        :formTextAreaList="formTestTextAreaList"
        @open-modal="openModal"
      ></base-form-items>

      <div class="text-orange-500 flex justify-start">
        <div class="mr-2">
          <span class="ml-4">
            <a-icon class="inline-block" type="exclamation-circle" />
          </span>
          说明:
        </div>
        <div>
          <div>
            {{ '1) 请自行选择送检机构并填写核酸检测、基因分型单价' }}
          </div>
          <span>
            {{ '2) 申请丙肝公费检测需满足投入产出比≤10%' }}
          </span>
        </div>
      </div>

      <base-form-title title="终端明细" icon="table" />
      <div class="p-4">
        <a-table
          bordered
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="form.hospitalList"
          :pagination="false"
          :rowClassName="
            record => {
              return hospId === record.id ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    hospId = record.id
                  }
                }
              }
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="nameTitle">
            <span class="app-form-required">终端名称</span>
          </template>
          <template slot="hospTitle">
            <span class="app-form-required">标准终端编码</span>
          </template>

          <!-- 序号 -->
          <template slot="index" slot-scope="text, record, index">
            <span>{{ index + 1 }}</span>
          </template>
          <!-- 终端名称用户填写 -->
          <template slot="name" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.name }}
            </span>
            <a-input v-else v-model="record.name"></a-input>
          </template>
          <!-- 选择标准终端 -->
          <template slot="code" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.hospitalCode }}
            </span>
            <a-input v-else v-model="record.hospitalCode" readOnly>
              <a-icon slot="suffix" type="search" @click="openHosp(record)" />
            </a-input>
          </template>
        </a-table>
        <a-button-group class="mt-2" v-if="!isFlow">
          <a-button icon="plus" @click="addLastHosp">添加</a-button>
          <a-button icon="minus" type="danger" @click="delLastHosp">
            删除
          </a-button>
        </a-button-group>
      </div>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDTO"
        :status="isFlow"
        :summaryid="form.flowInfoDTO.summaryid"
        :nextapproveman="form.flowInfoDTO.nextapproveman"
        :curApprover="form.flowInfoDTO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicantId" :billId="form.id"></comment>
    </base-form>

    <!-- 终端(医院管理) -->
    <hosp-modal
      :visible="showModal"
      @choose="onChooseHosp"
      @close="showModal = false"
    ></hosp-modal>

    <!-- 选择产品 -->
    <product-modal
      :visible="showProdVisible"
      :title="`选择产品`"
      @close="showProdVisible = false"
      @choose="onChooseProd"
    ></product-modal>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import HospModal from '@/views/outer/flowBill/components/HospModal'
import ProductModal from '@/views/outer/flowBill/components/ProductModal'
// api
import moment from 'moment'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { randomString } from '@/utils'
import {
  saveHcvApp,
  updateHcvApp,
  delHcvApp,
  getHcvApp,
  submitHcvApp
} from '@/api/acdemicActive/hcv-application'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import { getOACancel2 } from '@/api/acdemicActive/acdemic-application'
import { getHcvAgencyList } from '@/api/acdemicActive/hcv-agency'

// mixin
import userMixin from '../modules/mixins/user-mixin'
import verityMixin from '../modules/mixins/verity-mixin'

export default {
  name: 'HcvReimForm',
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    UploadModal,
    PreviewModal,
    Process,
    Comment,
    HospModal,
    ProductModal
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      /* eslint-disable prettier/prettier */
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,

      hospId: '',
      currentRow: {},
      showModal: false,
      showProdVisible: false, // 产品
      columns: [
        { title: '序号', dataIndex: 'index', width: 72, scopedSlots: { customRender: 'index' } },
        { dataIndex: 'name', width: 300, slots: { title: 'nameTitle' }, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'hospitalCode', width: 200, slots: { title: 'hospTitle' }, scopedSlots: { customRender: 'code' } },
        { title: '标准终端名称', dataIndex: 'hospitalName' },
        { title: '业务区域名称', dataIndex: 'chnlareaName' },
      ],

      agencyList: [], // 送检机构

      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      dataLoading: false, // 提交数据

      form: {
        appdate: '', // 申请日期
        applicantId: '', // 申请人id
        applicantName: '', // 申请人名称
        postName: '', //岗位
        contact: '', // 联系方式
        regionId: '', // 大区id
        region: '', // 大区名称
        provinceId: '', // 省办id
        province: '', // 省办名称

        vbillno: '', // 申请单号
        agencyId: '', // 送检机构id
        agencyName: '', // 送检机构名称
        startDate: '', // 送检开始日期
        endDate: '', // 送检结束日期
        appReason: '', // 申请理由

        productId: '', // 产品id
        productPrice: '', // 产品单价
        productCode: '', // 产品code
        productName: '', // 产品name
        nucleicAcidPrice: '', // 核酸检测单价
        genotypingPrice: '', // 基因分型单价
        nucleicAcidNum: '', // 核酸检测份数
        genotypingNum: '', // 基因分型份数
        estPatientNum: '', // 预计1型患者人数
        estPrescriptionNum: '', // 预计处方人数
        estCvr: '', // 预计转化率
        estInvest: '', // 本次预计投入（元）
        estOutput: '', // 预计预计产出（元）
        estIcor: '', // 投入产出比
        estIcorStr: '', // 投入产出比显示1：xx
        notes: '', // 备注

        hospitalList: [], // 终端明细
       
        flowInfoDTO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '申请单号', prop: 'vbillno', disabled: true },
        { label: '送检机构', prop: 'agencyId', type: 'select', options: [], disabled: () => this.getCommonDisabled },
        { label: '送检开始日期', prop: 'startDate', type: 'date', disabled: () => this.getCommonDisabled, disabledDate: () => this.disabledStDate },
        { label: '送检结束日期', prop: 'endDate', type: 'date', disabled: () => this.getCommonDisabled, disabledDate: () => this.disabledEndDate },
      ],

      formBaseTextAreaList: [
       { label: '申请理由', prop: 'appReason', disabled: () => this.getCommonDisabled },
      ],

      formTestList: [
        { label: '产品名称', prop: 'productName', type: 'search', disabled: () => this.getCommonDisabled },
        { label: '产品单价', prop: 'productPrice', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '核酸检测单价', prop: 'nucleicAcidPrice', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '基因分型单价', prop: 'genotypingPrice', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '核酸检测份数', prop: 'nucleicAcidNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '基因分型份数', prop: 'genotypingNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '预计1型患者人数', prop: 'estPatientNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '预计处方人数', prop: 'estPrescriptionNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '预计转化率', prop: 'estCvr', disabled: true },
        { label: '本次预计投入（元）', prop: 'estInvest', disabled: true },
        { label: '预计预计产出（元）', prop: 'estOutput', disabled: true },
        { label: '投入产出比', prop: 'estIcorStr', disabled: true },
      ],

      formTestTextAreaList: [
       { label: '备注', prop: 'notes', disabled: () => this.getCommonDisabled, required: false }
      ]
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    },
  },

  created() {
    const depts = this.$store.getters.depts || []

    if(depts.length > 0) {
      const deptList = depts.map(item => ({ 
        text: `${item.deptname}(${item.deptdesc === '1' ? '大区' : item.deptdesc === '2' ? '省办': '地办'})`, 
        value: item.did 
      }))

      this.formUserList.push({ 
        label: '部门', 
        prop: 'deptId', 
        type: 'select', 
        required: false, 
        options: deptList, 
        disabled: false, 
        show: ()=> !this.getCommonDisabled
      })
    }
    

    if(this.curType !== 'add') {
      getHcvApp(this.curPid).then(res => {
        this.form = res

        if (res.applicantId === this.$store.getters.uid) {
          const deptIds = depts.map(item => item.did)
          if(res.regionId && deptIds.includes(res.regionId)) this.$set(this.form, 'deptId', res.regionId)
          if(res.provinceId && deptIds.includes(res.provinceId)) this.$set(this.form, 'deptId', res.provinceId)
        }
        

        if(this.form.estIcor && this.form.estIcor > 0 ) this.form.estIcorStr = `1 : ${ this.form.estIcor }`

        // 单据状态(向上通知)
        const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
        this.$emit('status', status)
      })
    } else {
      this.$set(this.form, 'deptId', depts.length > 0 ? depts[0].did : '')
    }

    // 获取机构列表
    getHcvAgencyList(1, 100, []).then(res => {
      this.agencyList = res.datas
      this.formBaseList[1].options = res.datas.map(item => ({ text: item.name, value: item.id }))
    })
  },

  watch: {
    'form.nucleicAcidPrice': {
      handler(val) {
        this.calcEstInvest(val, this.form.genotypingPrice, this.form.nucleicAcidNum, this.form.genotypingNum)
      }
    },
    'form.genotypingPrice': {
      handler(val) {
        this.calcEstInvest(this.form.nucleicAcidPrice, val, this.form.nucleicAcidNum, this.form.genotypingNum)
      }
    },
    'form.nucleicAcidNum': {
      handler(val) {
        this.calcEstInvest(this.form.nucleicAcidPrice, this.form.genotypingPrice, val, this.form.genotypingNum)
      }
    },
    'form.genotypingNum': {
      handler(val) {
        this.calcEstInvest(this.form.nucleicAcidPrice, this.form.genotypingPrice, this.form.nucleicAcidNum, val)
      }
    },
    'form.productPrice': {
      handler(val) {
        this.calcEstOutput(this.form.estPrescriptionNum, val)
      }
    },
    'form.estPrescriptionNum': {
      handler(val) {
        this.calcEstOutput(val, this.form.productPrice)
        this.calcEstCvr(val, this.form.estPatientNum)
      }
    },
    'form.estInvest': {
      handler(val) {
        this.calcEstIcor(val, this.form.estOutput)
      }
    },
    'form.estOutput': {
      handler(val) {
        this.calcEstIcor(this.form.estInvest, val)
      }
    },
    'form.estPatientNum': {
      handler(val) {
        this.calcEstCvr(this.form.estPrescriptionNum, val)
      }
    }
  },

  methods: {
    moment,

    onUserChange(value, item) {
      // 费用承担部门改变
      if (item.prop === 'deptId') {
        this.form.region = ''
        this.form.regionName = ''
        this.form.province = ''
        this.form.provinceName = ''
        this.getParentDept(value)
      }
    },

    /**
     * 计算本次预计投入（元）
     * 计算规则：核酸检测单价*核酸检测份数+基因分型单价*基因分型份数
     * @param {*} nucleicAcidPrice 核酸检测单价
     * @param {*} genotypingPrice 基因分型单价
     * @param {*} nucleicAcidNum 核酸检测份数
     * @param {*} genotypingNum 基因分型份数
     */
    calcEstInvest(nucleicAcidPrice, genotypingPrice, nucleicAcidNum, genotypingNum) {
      if(
        (nucleicAcidPrice || nucleicAcidPrice == 0) &&
        (nucleicAcidNum || nucleicAcidNum == 0) && 
        (genotypingPrice || genotypingPrice == 0) && 
        (genotypingNum || genotypingNum == 0)
       ){
        this.form.estInvest = this.$np.plus(
          this.$np.times(nucleicAcidPrice, nucleicAcidNum),
          this.$np.times(genotypingPrice, genotypingNum)
        )
      }
    },

    /**
     * 计算预计预计产出（元）
     * 计算规则：预计处方人数*产品单价*3
     * @param {*} estPrescriptionNum 预计处方人数
     * @param {*} productPrice 产品单价
     */
    calcEstOutput(estPrescriptionNum, productPrice) {
      if(
        (estPrescriptionNum || estPrescriptionNum == 0) &&
        (productPrice || productPrice == 0)
       ){
        this.form.estOutput = this.$np.times(
          estPrescriptionNum || '0',
          this.$np.times(productPrice, 3)
        )
      }
    },

    /**
     * 计算投入产出比
     * 计算规则：预计产出/预计投入
     * @param {*} estInvest 预计投入（元）
     * @param {*} estOutput 预计预计产出（元）
     */
    calcEstIcor(estInvest, estOutput) {
      if(estInvest === 0 || estInvest === '0') {
        this.form.estIcor = ''
        this.form.estIcorStr = ''
        return
      } 
      
      if(estInvest  && (estOutput|| estOutput == 0)){
        this.form.estIcor = this.$np.round(this.$np.divide(
          estOutput,
          estInvest
        ), 2)

        this.form.estIcorStr = `1 : ${ this.form.estIcor }`
      } else { 
        this.form.estIcor = ''
        this.form.estIcorStr = ''
        return
      }
    },

    /**
     * 计算转化率
     * 计算规则：预计处方人数/预计1型患者人数
     * @param {*} estPrescriptionNum 预计处方人数
     * @param {*} estPatientNum 预计1型患者人数
     */
    calcEstCvr(estPrescriptionNum, estPatientNum) {
      if(estPatientNum === 0 || estPatientNum === '0') return
      
      if(estPatientNum && (estPrescriptionNum|| estPrescriptionNum == 0)){
        this.form.estCvr = this.$np.round(this.$np.divide(
          estPrescriptionNum,
          estPatientNum
        ), 2)
      }
    },

    openModal(item) {
      switch (item.prop) {
        case 'productName':
          this.showProdVisible = true
          break;
        default:
          break;
      }
    },

    onChooseHosp(hosp) {
      this.currentRow.hospitalId = hosp.id // 医院主键
      this.currentRow.hospitalCode = hosp.hospitalCode // 医院code
      this.currentRow.hospitalName = hosp.hospitalName // 医院名称
      this.currentRow.pkChnlarea = hosp.pkChnlarea // 业务区域pk
      this.currentRow.chnlareaCode = hosp.chnlareaCode // 业务区域code
      this.currentRow.chnlareaName = hosp.chnlareaName // 业务区域名称
    },

    onChooseProd({ id, name }) {
      this.form.productId = id
      this.form.productName = name
    },

    selectChange(value, item) {
      // 送检机构
      if(item.prop === 'agencyId') {
        const target = this.agencyList.find(v => v.id === value)

        if(target) {
          this.form.agencyName = target.name
        }
      }
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = this.form[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = this.form[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '上传文件':
          this.form.uploadInfo = fileListInfo
          break
        default:
          break
      }
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
     disabledStDate(time) {
      return (
        time && time >= moment(this.form.endDate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.form.startDate).add(-1, 'day').endOf('day')
      )
    },

    openHosp(record) {
      this.currentRow = record
      this.showModal = true
    },

    addLastHosp() {
      const pk = `hosp_${randomString(10)}`

      this.form.hospitalList.push({
        id: pk,
        hid: '', // 申请单主键
        name: '', // 终端名称
        hospitalId: '', // 标准终端id
        hospitalName: '', // 标准终端名称
        hospitalCode: '', // 标准终端编码
        pkChnlarea: '', // 业务区域PK
        chnlareaCode: '', // 业务区域名称
        chnlareaName: '' // 业务区域编码
      })
    },

    // 删除终端
    delLastHosp() {
      if(this.form.hospitalList.length){
        if(this.hospId) {
          this.form.hospitalList = this.form.hospitalList.filter(item => item.id !== this.hospId)
        }else {
          this.form.hospitalList.pop()
        }
      }
    },

    verityHosp() {
      for (let index = 0; index < this.form.hospitalList.length; index++) {
        const item = this.form.hospitalList[index]
        if(!item.name) {
          this.$message.error(`行号${index + 1}: 请填写终端名称`)
          return false
        }

        if(!item.hospitalId) {
          this.$message.error(`行号${index + 1}: 请选择标准终端`)
          return false
        }
        
      }

      return true
    },

    filterData() {
      const cloneData = cloneDeep(this.form)

      for (const item of (cloneData.hospitalList || [])) {
        if(item.id.startsWith('hosp_')){
          item.id = ''
        }
      }

      return cloneData
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateHcvApp : saveHcvApp

      const submitData = this.filterData()

      if (this.verityAppForm('save')) {

        if(this.form.id && !this.verityHosp()) return

        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.$router.replace({ name: 'hcvApplication' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {

        if(!this.verityHosp()) return

        this.dataLoading = true
        submitHcvApp(submitData).then(() => {
          this.delUploadFile()
          this.$message.success('提交成功')
          this.$router.replace({ name: 'hcvApplication' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该丙肝公费送检核销单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delHcvApp(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'hcvApplication' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该丙肝公费送检核销单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'GFSJ',
            this.form.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDTO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    } 
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
.app-form-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
