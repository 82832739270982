<template>
  <a-modal
    title="选择讲者"
    width="1000px"
    class="speaker-select-modal"
    centered
    destroyOnClose
    v-model="visible"
    @ok="handleOk"
  >
    <div class="w-full" style="height: 540px">
      <div class="w-full my-3">
        <a-button @click="prev" :disabled="!prePage">上一页</a-button>
        <a-button class="ml-2" @click="next" :disabled="!nextPage">
          下一页
        </a-button>
        <div class="search float-right">
          <a-input-search
            v-model="searchText"
            placeholder="按名称搜索"
            @search="onSearch"
            enterButton="搜索"
            class="w-72"
          />
        </div>
      </div>
      <a-table
        rowKey="speakerId"
        bordered
        :pagination="false"
        :columns="columns"
        :dataSource="speakerList"
        :loading="loading"
        @change="getActivetySpeaker"
        :rowSelection="{
          onChange: onSelectChange,
          selectedRowKeys: selectedRowKeys,
          onSelect: onSelect,
          onSelectAll: onSelectAll
        }"
        :rowClassName="
          (record, index) => {
            return index % 2 === 1 ? 'trBgClass' : ''
          }
        "
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column
          }"
          class="custom-filter-dropdown"
        >
          <a-input
            v-ant-ref="c => (searchInput = c)"
            placeholder="查询"
            :value="selectedKeys[0]"
            @change="
              e => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
            style="width: 180px; margin-bottom: 8px; display: block"
          />
          <a-button
            type="primary"
            @click="() => handleSearch(selectedKeys, confirm, column)"
            icon="search"
            style="width: 80px; margin-right: 10px"
          >
            查询
          </a-button>
          <a-button
            @click="() => handleReset(clearFilters, column)"
            style="width: 80px"
          >
            重置
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />

        <template slot="qyhSign" slot-scope="text, record">
          <span>{{ record.qyhSign ? '是' : '否' }}</span>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { getActivetySpeaker } from '@/api/acdemicActive/acdemic-application'
import { billSpeakerFilters } from '@/api/pubutil/tableutil'
import { getDoc } from '@/configs/doc-config'
import { default as pullAllWith } from 'lodash/pullAllWith'
import { default as pullAllBy } from 'lodash/pullAllBy'
import { default as cloneDeep } from 'lodash/cloneDeep'

export default {
  name: 'ChooseSpeakerModal',
  props: {
    //已选择的讲者信息
    speakers: {
      type: Array,
      default: () => []
    },
    isQyhSign: {
      type: Boolean,
      default: false
    },
    isInit: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      current: 1, //当前页
      pageSize: 10, //每页显示的数据
      nextPage: true, //是否有上一页
      prePage: false, //是否有下一页
      map: [], //模糊查询的条件
      speakerList: [],
      speakerIds: '',
      searchText: '',
      selectedRowKeys: [], //选择的行id
      selectedRows: [], //选择的行
      columns: [
        {
          title: '编号',
          dataIndex: 'code',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '名称',
          dataIndex: 'name',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '单位',
          dataIndex: 'departmentName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '级别',
          dataIndex: 'speakerLevelName',
          align: 'center',
          filters: []
        },
        {
          title: '是否区域会讲者',
          dataIndex: 'qyhSign',
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'qyhSign' }
        }
      ]
    }
  },
  created() {
    if (this.type === 'add') {
      this.map.push({ column: 'qyhSign', opt: '=', value: this.isQyhSign })
      this.getActivetySpeaker()
    }

    if (this.speakers && this.speakers.length > 0) {
      this.selectedRowKeys = this.speakers.map(item => item.speakerId)
      this.selectedRows = cloneDeep(this.speakers)
    }

    getDoc('018', res => (this.columns[3].filters = res))
  },

  watch: {
    'speakers.length': function (val) {
      if (val > 0) {
        this.selectedRowKeys = this.speakers.map(item => item.speakerId)
        this.selectedRows = cloneDeep(this.speakers)
      } else {
        this.selectedRowKeys = []
        this.selectedRows = []
      }
    },

    isQyhSign: function (val) {
      if (!val) {
        const target = this.map.find(item => item.column === 'qyhSign')
        !target && this.map.push({ column: 'qyhSign', opt: '=', value: val })
      } else pullAllBy(this.map, [{ column: 'qyhSign' }], 'column')

      if (!this.isInit) {
        this.current = 1
        this.selectedRowKeys = []
        this.selectedRows = []
        this.$emit('clearSpeakers')
      }

      this.getActivetySpeaker()
    }
  },

  methods: {
    // 按条件查询
    handleSearch(selectedKeys, confirm, column) {
      if (selectedKeys[0]) {
        pullAllBy(this.map, [{ column: column.dataIndex }], 'column')
        this.map.push({
          column: column.dataIndex,
          opt: 'like',
          value: selectedKeys[0]
        })
      } else {
        this.map = this.map.filter(item => item.column !== column.dataIndex)
      }

      confirm()
    },

    // 重置
    handleReset(clearFilters, column) {
      this.map = this.map.filter(item => item.column !== column.dataIndex)
      clearFilters()
    },

    // 选择单个讲者的信息
    // onSelectChange(selectedRowKeys) {
    //   for (let speakerItem of this.speakers) {
    //     if (!speakerItem.speakerId) continue
    //     if (selectedRowKeys[0] === speakerItem.speakerId) {
    //       this.$message.error('该讲者已在讲者列表中，请选择其他讲者')
    //       return
    //     }
    //   }
    //   this.selectedRowKeys = selectedRowKeys
    //   this.speakerIds = selectedRowKeys[0]
    // },

    // 选择的信息
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    /**
     * 手动选择
     * @param {Object} record
     * @param {Boolean} selected
     */
    onSelect(record, selected) {
      if (selected) {
        this.selectedRows.push(record)
      } else {
        pullAllWith(
          this.selectedRows,
          [record],
          (a, b) => a.speakerId === b.speakerId
        )
      }
    },

    /**
     * 全选
     * @param {Boolean} selected
     * @param {Array} selectedRows
     * @param {Array} changeRows
     */
    onSelectAll(selected, selectedRows, changeRows) {
      if (selected) {
        this.selectedRows = [...this.selectedRows, ...changeRows]
      } else {
        pullAllWith(
          this.selectedRows,
          changeRows,
          (a, b) => a.speakerId === b.speakerId
        )
      }
    },

    // 确定
    handleOk() {
      this.visible = false

      // 解决model闪烁的问题
      setTimeout(() => this.$emit('chooseSpeaker', this.selectedRows), 500)
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的讲者信息
     */
    // sclick(item) {
    //   const speakers = []
    //   speakers.push(item.speakerId)
    //   this.onSelectChange(speakers)
    // },

    // 按名字搜索
    onSearch(value) {
      if (value === '') {
        if (!this.isQyhSign) {
          this.map = this.map.filter(item => item.column === 'qyhSign')
        } else {
          this.map = []
        }
      } else {
        if (!this.isQyhSign) {
          this.map = [{ column: 'qyhSign', opt: '=', value: this.isQyhSign }]
        }

        this.map.push({
          column: 'name',
          opt: 'like',
          value: value
        })
      }
      this.current = 1
      this.getActivetySpeaker()
    },

    // 上一页
    prev() {
      this.current = this.current - 1
      this.getActivetySpeaker()
    },

    // 下一页
    next() {
      this.current = this.current + 1
      this.getActivetySpeaker()
    },

    // 获取讲者的信息
    getActivetySpeaker(pagination, filters) {
      if (pagination) this.current = 1
      if (filters) {
        const list = billSpeakerFilters(filters)
        this.map = this.map.filter(item =>
          ['code', 'name', 'departmentName', 'qyhSign'].includes(item.column)
        )
        this.map = [...this.map, ...list]
      }

      this.loading = true
      getActivetySpeaker(this.current, this.pageSize, this.map).then(
        response => {
          this.current = response.currentPage
          this.prePage = response.prePage
          this.nextPage = response.nextPage
          this.speakerList = []
          response.datas.forEach(item => {
            let obj = {}
            this.$set(obj, 'id')
            this.$set(obj, 'speakerId', item.id)
            this.$set(obj, 'code', item.code)
            this.$set(obj, 'name', item.name)
            this.$set(obj, 'departmentName', item.departmentName)
            this.$set(obj, 'speakerLevelName', item.speakerLevelDTO?.name)
            this.$set(obj, 'consultingFee', null)
            this.$set(obj, 'pid', '')
            this.$set(obj, 'qyhSign', item.qyhSign)
            this.speakerList.push(obj)
          })
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang="less">
.speaker-select-modal .ant-modal-body {
  padding: 8px 16px;
}
</style>
