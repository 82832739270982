<template>
  <div class="relative">
    <div class="example" v-if="loading">
      <loading-stuff title="总结单加载中..." class="loading-icon" />
    </div>

    <replay-notify
      :show="
        showNotification &&
        !$route.query.todo &&
        type !== 'waitwork' &&
        form.flowInfoDTO.status === '96'
      "
      @replay="replay"
    />

    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      type="flex"
      align="middle"
    >
      <a-col :span="12">
        <span v-if="mTimeStr" style="padding-left: 16px; color: #4587f5">
          <icon-font class="text-base" type="icon_huiyidengji"></icon-font>
          <span class="ml-2">{{ mTimeStr }}</span>
        </span>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-button type="primary" :disabled="isFlow" @click="save">
          保存
        </a-button>
        <a-button
          type="primary"
          class="ml-2"
          :disabled="isFlow"
          @click="submit"
        >
          提交
        </a-button>
        <a-button
          type="danger"
          class="ml-2"
          :disabled="!isEnd || !form.id"
          @click="del"
        >
          删除
        </a-button>
        <a-button class="ml-2" :disabled="backDisabled" @click="getBack">
          撤销
        </a-button>
        <a-divider type="vertical"></a-divider>
        <a-button @click="showModal = true">打印总结单</a-button>
        <a-divider type="vertical"></a-divider>
        <a-button @click="$router.go(-1)">返回</a-button>
      </a-col>
    </a-row>

    <a-row v-else class="mt-4" type="flex" align="middle">
      <a-col :span="12">
        <span v-if="mTimeStr" style="padding-left: 16px; color: #4587f5">
          {{ mTimeStr }}
        </span>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-button @click="openApp(form.activityAppId)">申请单</a-button>
        <a-button class="ml-2 mr-4" @click="showModal = true">
          打印总结单
        </a-button>
      </a-col>
    </a-row>

    <a-form class="pl-4" labelAlign="left">
      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="user" />
        <span class="ml-2">用户信息</span>
      </div>
      <a-row class="p-4">
        <a-col :xl="6" :xxl="4" v-for="item in userForm" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :required="!item.disabled"
          >
            <a-select
              v-if="item.type === 'select'"
              v-model="user[item.prop]"
              :disabled="isFlow || item.disabled"
            >
              <a-select-option
                v-for="doc in item.options"
                :key="doc.value"
                :value="doc.value"
              >
                {{ doc.text }}
              </a-select-option>
            </a-select>
            <a-input
              v-else
              v-model="user[item.prop]"
              :disabled="isFlow || item.disabled"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="gift" />
        <span class="ml-2">活动信息</span>
      </div>
      <a-row class="p-4">
        <template v-for="item in activityForm">
          <a-col
            v-if="!item.show || item.show()"
            :xl="6"
            :xxl="4"
            :key="item.prop"
          >
            <a-form-item
              v-if="!item.show || item.show()"
              :label="item.label"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              :required="!item.disabled"
            >
              <a-select
                v-if="item.type === 'select'"
                v-model="activity[item.prop]"
                :disabled="isFlow || item.disabled"
              >
                <a-select-option
                  v-for="doc in item.options"
                  :key="doc.value"
                  :value="doc.value"
                >
                  {{ doc.text }}
                </a-select-option>
              </a-select>
              <a-date-picker
                v-else-if="item.type === 'date'"
                class="w-full"
                format="YYYY-MM-DD"
                :value="
                  activity[item.prop] ? moment(activity[item.prop]) : null
                "
                :allowClear="false"
                :disabledDate="item.disabledDate()"
                :disabled="isFlow || item.disabled"
                @change="onDateChange(arguments, item)"
              />
              <a-input
                v-else-if="item.prop === 'actualActivityPlace'"
                v-model="activity[item.prop]"
                :disabled="isFlow || item.disabled"
                :title="activity[item.prop]"
                readOnly
              >
                <a-icon
                  slot="suffix"
                  type="environment"
                  @click="
                    item => {
                      if (isFlow || item.disabled) return
                      showMap = true
                    }
                  "
                />
              </a-input>
              <a-input
                v-else
                v-model="activity[item.prop]"
                :disabled="isFlow || item.disabled"
                :oninput="item.validate ? oninput[item.validate] : ''"
                :title="activity[item.prop]"
              ></a-input>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
      <a-row class="px-4">
        <template v-for="item in activityFileForm">
          <a-col
            v-if="!item.show || item.show()"
            :xl="6"
            :xxl="4"
            :key="item.prop"
          >
            <a-form-item v-if="!item.show || item.show()">
              <div class="flex items-center">
                <span class="relative">
                  <span :class="[item.notVerity ? '' : 'app-form-required']">
                    {{ item.label }} :
                  </span>
                  <span
                    class="absolute text-white cursor-pointer"
                    style="right: -30px"
                    @click="openFile(item)"
                  >
                    <span v-if="item.prop === 'signinForm' && isAfterSignTable">
                      <a-icon
                        :style="{ fontSize: '12px', color: '#fff' }"
                        type="table"
                      />
                    </span>
                    <span v-else>
                      <!-- 现场照片数量判断使用(photosOnSiteNumber) -->
                      {{
                        item.prop !== 'pictures'
                          ? activity[item.prop]
                            ? JSON.parse(activity[item.prop]).length
                            : '0'
                          : form.photosOnSiteNumber || 0
                      }}
                    </span>
                  </span>
                  <span
                    v-if="item.deprecated === true"
                    class="absolute text-red-600"
                    style="right: -90px"
                  >
                    ( 停用 )
                  </span>
                </span>
                <icon-svg
                  icon="icon_wenjianjia3"
                  class="text-4xl ml-2 cursor-pointer"
                  @click="openFile(item)"
                />
              </div>
            </a-form-item>
          </a-col>
        </template>
      </a-row>

      <a-row class="p-4">
        <a-col :span="24" v-for="item in activityTextareaForm" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelTextAreaCol"
            :wrapper-col="wrapperTextAreaCol"
            :required="!item.disabled"
          >
            <a-textarea
              v-model="activity[item.prop]"
              placeholder="800字内"
              :disabled="isFlow || item.disabled"
              :maxLength="800"
              :autoSize="{ minRows: 1, maxRows: 6 }"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="schedule" />
        <span class="ml-2">活动类型及执行标准</span>
      </div>
      <a-row class="px-4 pt-4">
        <a-col v-for="item in standardForm" :xl="6" :xxl="4" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <!-- 活动类型 -->
            <span v-if="item.prop === 'acttype'" class="relative">
              <a-input
                readOnly
                v-model="standard.activityTypeStdDTO[item.prop]"
                :title="standard.activityTypeStdDTO[item.prop]"
                :disabled="isFlow || item.disabled"
                @click="showActiveVisible"
              ></a-input>
              <a-avatar
                v-if="standard.activityTypeStdDTO[item.prop]"
                shape="square"
                class="absolute cursor-pointer"
                title="查看活动类型及执行标准详情"
                :size="18"
                :style="{
                  backgroundColor: '#fd875c',
                  verticalAlign: 'middle',
                  right: '-8px',
                  top: '-16px'
                }"
                @click.stop="
                  viewActivityTypeDetail(standard.activityTypeStdDTO)
                "
              >
                详
              </a-avatar>
            </span>
            <a-input
              v-else-if="
                item.type === 'between' &&
                (standard.activityTypeStdDTO[item.propGt] ||
                  standard.activityTypeStdDTO[item.propGt] === 0)
              "
              :value="`${standard.activityTypeStdDTO[item.propGt]} ~ ${
                standard.activityTypeStdDTO[item.propLt]
              }`"
              :disabled="isFlow || item.disabled"
            ></a-input>
            <a-input
              v-else
              v-model="standard.activityTypeStdDTO[item.prop]"
              :title="standard.activityTypeStdDTO[item.prop]"
              :disabled="isFlow || item.disabled"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="px-4">
        <a-col :span="24" v-for="item in standardTextareaForm" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelTextAreaCol"
            :wrapper-col="wrapperTextAreaCol"
          >
            <a-textarea
              v-model="standard.activityTypeStdDTO[item.prop]"
              placeholder="800字内"
              :title="standard.activityTypeStdDTO[item.prop]"
              :disabled="isFlow || item.disabled"
              :maxLength="800"
              :autoSize="{ minRows: 1, maxRows: 6 }"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="team" />
        <span class="ml-2">实际陪同人员信息</span>
        <a-popover trigger="hover">
          <template slot="content">
            <p class="p-2">
              1、活动信息-内部人员数应与实际陪同人员信息人数一致。
            </p>
          </template>
          <a-icon class="ml-2" type="exclamation-circle" />
        </a-popover>
      </div>
      <div class="p-4">
        <a-table
          bordered
          rowKey="personId"
          size="middle"
          :columns="accompanyColumns"
          :dataSource="accompany.appAccompanyingPersonsDtos"
          :pagination="false"
          :rowClassName="
            record => {
              return accompanyId === record.personId ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    accompanyId = record.personId
                  }
                }
              }
            }
          "
        >
          <!-- 表格标题 -->
          <!-- <template slot="budgetTitle">
            <span class="app-form-required">实际陪同预算</span>
          </template> -->

          <!-- 陪同人员序号 -->
          <template slot="number" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
          <!-- 陪同人员工号 -->
          <template slot="code" slot-scope="text, record, index">
            <span>
              {{ text }}
            </span>
            <a-icon
              v-if="!isFlow"
              type="search"
              class="cursor-pointer hover:text-blue-500 float-right mt-1"
              @click="showPersonVisible('update', index)"
            ></a-icon>
          </template>
          <!-- 陪同预算 -->
          <!-- <template slot="budget" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.accompanyBudget }}
            </span>
            <a-input
              v-else
              v-model="record.accompanyBudget"
              :oninput="oninput['digit']"
              @change="calcBudget"
            ></a-input>
          </template> -->
        </a-table>
        <a-button-group v-if="!isFlow">
          <a-button
            icon="plus"
            title="从最后一位陪同人员后增加一位陪同人员"
            @click="showPersonVisible('add')"
          >
            添加
          </a-button>
          <a-button
            icon="minus"
            type="danger"
            title="删除最后一位陪同人员"
            @click="delLastAccPerson"
          >
            删除
          </a-button>
        </a-button-group>
        <!-- <div class="mt-2 text-right">
          <span class="font-bold">实际陪同费用 :</span>
          <span class="ml-2 text-red-600">
            {{ accompany.accompanyExpense }}
          </span>
        </div> -->
      </div>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="usergroup-add" />
        <span class="ml-2">实际讲者信息</span>
        <a-popover trigger="hover">
          <template slot="content">
            <p class="p-2">
              1、实际讲者人数需要在选择的活动类型及执行标准范围内(总部项目名称某些枚举项除外)。
              <br />
              2、活动信息-讲者人数应与实际讲者信息人数一致。
              <br />
              3、当切换活动类型（区域会和非区域会）时，会清空讲者列表，请先选择活动类型后再选择讲者。
            </p>
          </template>
          <a-icon class="ml-2" type="exclamation-circle" />
        </a-popover>
      </div>
      <div class="p-4">
        <a-table
          bordered
          rowKey="speakerId"
          size="middle"
          :columns="speakerColumns"
          :dataSource="speaker.speakers"
          :pagination="false"
          :rowClassName="
            record => {
              return speakerId === record.speakerId ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    speakerId = record.speakerId
                  }
                }
              }
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="feeTitle">
            <span class="app-form-required">讲者劳务费</span>
          </template>
          <template slot="topicTitle">
            <span class="app-form-required">讲者课题</span>
          </template>
          <template slot="lectureTitle">
            <span class="app-form-required">讲课时长(分钟)</span>
          </template>

          <!-- 讲者序号 -->
          <template slot="number" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>

          <!-- 讲者姓名 -->
          <template slot="name" slot-scope="text, record, index">
            <a style="color: #4587f5" @click="showSpeakerInfo(index)">
              {{ text }}
            </a>
            <a-icon
              v-if="!isFlow"
              type="search"
              class="cursor-pointer hover:text-blue-500 float-right mt-1"
              @click="showSpeakerVisible('update', index)"
            ></a-icon>
          </template>
          <!-- 讲者咨询费 -->
          <template slot="fee" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.appConsultingFee }}
            </span>
            <a-input
              v-else
              v-model="record.appConsultingFee"
              :oninput="oninput['digit']"
              @change="calcFee"
            ></a-input>
          </template>
          <!-- 讲者课题 -->
          <template slot="topic" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.topicname }}
            </span>
            <a-input v-else v-model="record.topicname"></a-input>
          </template>
          <!-- 讲课时长 -->
          <template slot="lecture" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.lengthoflecture }}
            </span>
            <a-input
              v-else
              v-model="record.lengthoflecture"
              :oninput="oninput['number']"
            ></a-input>
          </template>
        </a-table>
        <a-button-group v-if="!isFlow">
          <a-button
            icon="plus"
            title="从最后一位讲者后增加一位讲者"
            @click="showSpeakerVisible('add')"
          >
            添加
          </a-button>
          <a-button
            icon="minus"
            type="danger"
            title="删除最后一位讲者"
            @click="delLastSpeaker"
          >
            删除
          </a-button>
        </a-button-group>
        <div class="mt-2 text-right">
          <span class="font-bold">讲者劳务费实际合计 :</span>
          <span class="ml-2 text-red-600">
            {{ speaker.budgetConsultTotal }}
          </span>
        </div>
      </div>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="pay-circle" />
        <span class="ml-2">费用信息</span>
      </div>
      <div class="p-4">
        <a-table
          bordered
          rowKey="title"
          :columns="feeColumns"
          :dataSource="feeDatas"
          :pagination="false"
          :rowClassName="
            record => {
              return record.deprecated ? 'deprecated-row' : ''
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="priceTitle">
            <span class="app-form-required">实际单价</span>
          </template>
          <template slot="numTitle">
            <span class="app-form-required">实际数量</span>
          </template>
          <template slot="totalTitle">
            <span class="app-form-required">实际总价</span>
          </template>

          <!-- 附件上传 -->
          <template slot="upload" slot-scope="text, record">
            <template v-if="record.deprecated">
              <span>~</span>
            </template>
            <template v-else>
              <a style="color: #4587f5" @click="openInvioce(record)">
                <span>发票</span>
                <span>
                  (
                  {{
                    cooptBillInfos.filter(
                      i => i.invoiceFeeTypeName === record.title
                    ).length
                  }}
                  )
                </span>
              </a>
              <a-divider type="vertical" />
              <a style="color: #4587f5" @click="openFile(record)">
                <span>其他</span>
                <span>
                  (
                  {{ JSON.parse(fee[record.upload] || '[]').length }}
                  )
                </span>
              </a>
            </template>
          </template>

          <!-- 预算总价 -->
          <template slot="reimTotal" slot-scope="text, record">
            <span v-if="typeof record.reimTotal === 'function'">
              {{ record.reimTotal() }}
            </span>
            <span v-else>
              {{ fee[record.reimTotal] || 0 }}
            </span>
          </template>
          <!-- 预算数量 -->
          <template slot="reimNum" slot-scope="text, record">
            <span v-if="record.reimNum === '~'">
              {{ record.reimNum }}
            </span>
            <span v-else>
              {{ fee[record.reimNum] || 0 }}
            </span>
          </template>
          <!-- 预算单价 -->
          <template slot="reimPrice" slot-scope="text, record">
            <span v-if="record.reimPrice === '~'">
              {{ record.reimPrice }}
            </span>
            <span v-else>
              {{ fee[record.reimPrice] || 0 }}
            </span>
          </template>

          <!-- 实际总价 -->
          <template slot="total" slot-scope="text, record">
            <span v-if="typeof record.total === 'function'">
              {{ record.total() }}
            </span>
            <span v-else-if="isFlow || record.deprecated">
              {{ fee[record.total] || 0 }}
            </span>
            <span v-else-if="record.canInput && !record.canInput()">
              {{ fee[record.total] || 0 }}
            </span>
            <a-input
              v-else
              v-model="fee[record.total]"
              :oninput="oninput['digit']"
            ></a-input>
          </template>
          <!-- 实际数量 -->
          <template slot="num" slot-scope="text, record">
            <span v-if="record.num === '~'">
              {{ record.num }}
            </span>
            <span v-else-if="isFlow || record.deprecated">
              {{ fee[record.num] || 0 }}
            </span>
            <span v-else-if="record.canInput && !record.canInput()">
              {{ fee[record.num] || 0 }}
            </span>
            <a-input
              v-else
              v-model="fee[record.num]"
              :oninput="oninput['number']"
            ></a-input>
          </template>
          <!-- 实际单价 -->
          <template slot="price" slot-scope="text, record">
            <span v-if="record.price === '~'">
              {{ record.price }}
            </span>
            <span v-else-if="isFlow || record.deprecated || record.calcTotal">
              {{ fee[record.price] || 0 }}
            </span>
            <span v-else-if="record.canInput && !record.canInput()">
              {{ fee[record.price] || 0 }}
            </span>
            <a-input
              v-else
              v-model="fee[record.price]"
              :oninput="oninput['digit']"
            ></a-input>
          </template>
        </a-table>
        <a-row type="flex" justify="end" class="mt-2">
          <a-col :span="4">
            <span class="font-bold">已预付金额 :</span>
            <span class="text-red-600 ml-2">{{ fee.budgetPreCost }}</span>
          </a-col>
          <a-col :span="4">
            <span class="font-bold">预算合计金额 :</span>
            <span class="text-red-600 ml-2">{{ fee.reimbudgetTotal }}</span>
          </a-col>
          <a-col :span="4">
            <span class="font-bold">实际花销金额 :</span>
            <span class="text-red-600 ml-2">{{ fee.budgetTotal }}</span>
          </a-col>
          <a-col :span="4">
            <span class="font-bold">批准报销金额 :</span>
            <span class="text-red-600 ml-2">{{ fee.reimAmountApprove }}</span>
          </a-col>
          <a-col :span="4">
            <span class="font-bold">财务批准金额 :</span>
            <span class="text-red-600 ml-2">
              {{ fee.financialApprovalAmount }}
            </span>
          </a-col>
        </a-row>
      </div>

      <!-- 发起人附言 -->
      <post-script
        v-if="form.flowInfoDTO"
        billType="XSBX"
        title="补充材料说明"
        nullText="没有补充材料说明"
        btnText="新增材料说明"
        :pid="pid"
        :uid="form.applicant"
        :status="form.flowInfoDTO.status"
      ></post-script>
      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDTO"
        :status="isFlow"
        :summaryid="form.flowInfoDTO.summaryid"
        :nextapproveman="form.flowInfoDTO.nextapproveman"
        :curApprover="form.flowInfoDTO.curApprover"
      />
      <!-- 审批意见 -->
      <comment
        v-if="form.flowInfoDTO"
        ref="comment"
        :uid="form.applicant"
        :billId="form.id"
        :status="form.flowInfoDTO.status"
      ></comment>
    </a-form>

    <a-drawer
      title="讲者信息"
      placement="right"
      width="720"
      :closable="false"
      :visible="speakerVisible"
      @close="speakerVisible = false"
    >
      <speaker-drawer
        ref="speakerDrawer"
        :speakers="speaker.speakers"
        :currentIdx="drawerInx"
      ></speaker-drawer>
    </a-drawer>

    <a-drawer
      title="活动类型及执行标准详情"
      placement="right"
      width="720"
      :closable="false"
      :visible="standardVisible"
      @close="standardVisible = false"
    >
      <standard-drawer :avtivityType="standardInfo"></standard-drawer>
    </a-drawer>

    <!-- 选择活动类型 -->
    <choose-active-modal
      ref="activemodel"
      :conferenceType="getConferenceType"
      @activeType="chooseActive"
    ></choose-active-modal>

    <!-- 选择陪同人员 -->
    <choose-accompany-modal
      ref="accompanymodel"
      :accPersonList="accompany.appAccompanyingPersonsDtos"
      @chooseAccPerson="chooseAccPerson"
    ></choose-accompany-modal>

    <!-- 选择讲者 -->
    <choose-speaker-modal
      ref="speakermodel"
      :isQyhSign="isQyMeeting"
      :speakers="speaker.speakers"
      :isInit="isInit"
      :type="type"
      @chooseSpeaker="chooseSpeaker"
      @clearSpeakers="
        () => {
          if (!isInit) speaker.speakers = []
          calcFee()
        }
      "
    ></choose-speaker-modal>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :seeSign="type === 'waitwork' && seeLocationSignPerm"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <!-- 学术总结打印 -->
    <print-wrapper ref="printWrapper" v-show="printVisible">
      <xsbx-print
        :datas="form"
        :options="options"
        :productList="productList"
        :businessTypeList="businessTypeList"
        :activityFormList="activityFormList"
      ></xsbx-print>
    </print-wrapper>

    <!-- 打印选择附言、审批意见、流程信息 -->
    <modal
      v-if="showModal"
      title="选择打印的信息"
      @handldClick="handldPrintlick"
      @close="showModal = false"
    >
      <a-checkbox-group
        v-model="options"
        name="checkboxgroup"
        :options="plainOptions"
      />
    </modal>
    <!-- 电子签到表 -->
    <a-modal
      v-model="showSignTableModal"
      title="电子签到表"
      width="1080px"
      @ok="showSignTableModal = false"
      @cancel="showSignTableModal = false"
    >
      <sign-table isReim :pkSrc="form.activityAppId"></sign-table>
    </a-modal>

    <!-- 实际活动地点定位 -->
    <map-modal
      v-if="showMap"
      @close="showMap = false"
      @confirm="getActivityPlace"
    ></map-modal>

    <!-- 费用项发票选择 -->
    <invioce-modal
      v-if="showInvioce"
      :status="isFlow"
      :pid="pid"
      :feeItem="chooseFeeItem"
      :feeItemInvioces="feeItemInvioces"
      :cooptBillInfos="cooptBillInfos"
      :feeProjectEmunItem="feeProjectEmunItem"
      @chooseInvioces="chooseInvioces"
      @close="showInvioce = false"
      @removeCooptBillInfos="removeCooptBillInfos"
    ></invioce-modal>
  </div>
</template>

<script>
// mixin
import userMixin from './mixins/user-mix'
import activityMixin from './mixins/activity-mix'
import standardMixin from './mixins/standard-mix'
import accompanyMixin from './mixins/accompany-mix'
import speakerMixin from './mixins/speaker-mix'
import feeMixin from './mixins/fee-mix'
import verityMixin from './mixins/verity-mix'

// components
import SpeakerDrawer from '@/views/activity/academicApplication/academic-v2/components/SpeakerDrawer'
import StandardDrawer from '@/views/activity/academicApplication/academic-v2/components/StandardDrawer'
import ChooseActiveModal from '@/views/activity/academicApplication/academic-v2/components/ChooseActiveModal'
import ChooseSpeakerModal from '@/views/activity/academicApplication/academic-v2/components/ChooseSpeakerModal'
import ChooseAccompanyModal from '@/views/activity/academicApplication/academic-v2/components/ChooseAccompanyModal'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import PostScript from '@/components/flow/PostScript'
import LoadingStuff from '@/components/spin/LoadingStuff'
import Modal from '@/components/Modal'
import ReplayNotify from '@/components/ReplayNotify'
import PrintWrapper from 'vue-easy-print'
import XsbxPrint from './components/XsbxPrint'
import SignTable from '@/views/activity/academicApplication/academic-v2/SignTable'
import MapModal from '@/views/activity/academicApplication/academic-v2/components/MapModal'
import InvioceModal from './components/InvioceModal.vue'
// api
import { isDisabled } from '@/utils/util'
import { getCrmHttp } from '@/views/activity/modules/util'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import { getMeetingPhoto } from '@/api/acdemicActive/meeting'
import {
  getActivityAppById,
  getOACancel2
} from '@/api/acdemicActive/acdemic-application'
import {
  getActivityReimbursement,
  getEditActivityReimbursement,
  getActivityReimbursementById,
  getDeleteActivityReimbursement,
  getSubmitXSBX
} from '@/api/acdemicActive/acdemic-expense'
import { oninput } from '@/utils/validate'
import { SIGN_TABLE_DATE, PICTURES_DATE } from '@/configs/date-config'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'ReimFormDetail',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    SpeakerDrawer,
    StandardDrawer,
    ChooseActiveModal,
    ChooseSpeakerModal,
    ChooseAccompanyModal,
    UploadModal,
    PreviewModal,
    Process,
    Comment,
    PostScript,
    LoadingStuff,
    Modal,
    PrintWrapper,
    XsbxPrint,
    SignTable,
    MapModal,
    InvioceModal,
    ReplayNotify
  },
  mixins: [
    userMixin,
    activityMixin,
    standardMixin,
    accompanyMixin,
    speakerMixin,
    feeMixin,
    verityMixin
  ],
  data() {
    return {
      labelCol: { span: 20 },
      wrapperCol: { span: 20 },
      labelTextAreaCol: { span: 20 },
      wrapperTextAreaCol: { span: 24 },

      showNotification: false,
      showSignTableModal: false, // 电子签到表

      // 打印
      printVisible: false,
      showModal: false,
      options: ['flow', 'ps', 'comment'],
      plainOptions: [
        { label: '流程信息', value: 'flow' },
        { label: '发起人附言', value: 'ps' },
        { label: '审批意见', value: 'comment' }
      ],

      oninput,

      isInit: false,
      loading: false,
      mTimeStr: '',
      seeLocationSignPerm: false, // 是否有查看相似照片的权限
      form: { flowInfoDTO: { status: '1' } },

      picturesPriview: '', // 现场照片字段
      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: ''
    }
  },

  created() {
    const api =
      this.type === 'add' ? getActivityAppById : getActivityReimbursementById

    this.loading = true
    this.isInit = true // 表单数据是否在初始化。

    api(this.pid)
      .then(res => {
        this.form = res
        // 初始化数据
        this.initData(this.type)
        // 数据分发
        this.distributeData(this.user, false, this.type)
        this.distributeData(this.activity, false, this.type)
        this.distributeData(this.standard, false, this.type)
        this.distributeData(this.accompany, false, this.type)
        this.distributeData(this.speaker, false, this.type)
        this.distributeData(this.fee, true, this.type)
      })
      .finally(() => {
        this.isInit = false
        this.loading = false
      })
  },

  mounted() {
    const menu = this.$store.getters.usermenus
    const { permissions = [] } =
      menu.find(item => item.code === 'queryActivityReimbursement') || {}
    const targetPerm = permissions.find(
      v => v.url === 'query:viewLocationAndManualFileSign'
    )
    if (targetPerm) this.seeLocationSignPerm = true
  },

  computed: {
    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      return isDisabled(status)
    },

    showAvatar() {
      return this.$store.getters.protal
    },

    // 单据是否终止
    isEnd() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      return status === '15' || !this.isFlow
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    isAfterPictures() {
      return this.$date(this.form.activityAppdate).isAfter(
        this.$date(PICTURES_DATE)
      )
    },

    isAfterSignTable() {
      return this.$date(this.form.activityAppdate).isAfter(
        this.$date(SIGN_TABLE_DATE)
      )
    }
  },

  methods: {
    initData(type) {
      if (type === 'add') {
        this.form.activityAppId = this.form.id
        this.form.id = '' // 单据id为空
        this.form.speakerNum = '' // 讲者到场人数
        this.form.internalnum = '' // 内部人员到场数
        this.form.meetingMaterialExpenseDocs = '' // 申请单和总结单的字段一样(需要取消)
        this.$set(this.form, 'activityAppdate', this.form.appdate) // 申请单的申请日期
        this.$set(this.form, 'appdate', this.$date().format('YYYY-MM-DD')) // 申请单的申请日期
        this.$set(this.form, 'flowInfoDTO', { status: '1' }) // 单据状态初始化
        this.$set(this.form, 'actualActivityPlace', this.form.activityPlace)
        // 陪同人员去掉id
        if (
          this.form.appAccompanyingPersonsDtos &&
          this.form.appAccompanyingPersonsDtos.length
        ) {
          for (const item of this.form.appAccompanyingPersonsDtos) {
            item.id = ''
          }
        }

        // 讲者信息去掉id
        if (this.form.speakers && this.form.speakers.length) {
          for (const item of this.form.speakers) {
            item.id = ''
          }
        }
      }

      // 申请人
      this.$set(this.form, 'appName', this.form.applicantDTO.username)
      this.$set(this.form, 'appUserCode', this.form.applicantDTO.userCode)
      // 大区
      if (this.form.regionDTO)
        this.$set(this.form, 'regionName', this.form.regionDTO.deptname)
      // 省区
      if (this.form.provinceDTO)
        this.$set(this.form, 'provinceName', this.form.provinceDTO.deptname)
      // 地办
      if (this.form.localityDTO)
        this.$set(this.form, 'localityName', this.form.localityDTO.deptname)
      // 费用承担部门
      if (this.form.departmentDTO)
        this.$set(this.form, 'departmentName', this.form.departmentDTO.deptname)
      // 所属层级
      if (this.form.conferenceTypeDTO)
        this.$set(
          this.form,
          'conferenceTypeName',
          this.form.conferenceTypeDTO.name
        )

      // 会议场数
      const { meetingsTimeStatistics: m } = this.form
      if (m) {
        this.mTimeStr = `${m.activityTypeName}, ${m.yearMonth}年`
        if (m.areaYearTimes || m.provinceYearTimes || m.localityYearTimes) {
          this.mTimeStr += `${this.form.regionName}, `
          this.mTimeStr += `大区层级共${m.areaYearTimes || '0'}场, `
          this.mTimeStr += `省区层级共${m.provinceYearTimes || '0'}场, `
          this.mTimeStr += `地区层级共${m.localityYearTimes || '0'}场, `
        } else {
          this.mTimeStr += `总部层级共${m.yearTimes || '0'}场，`
        }

        this.mTimeStr += `本次为‘费用承担部门’第${m.deptYearSeq}/${m.deptYearTimes}场`
      }

      // 单据状态(向上通知)
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      // 处理待发无流程状态的问题
      if (!this.form.flowInfoDTO) {
        this.form.flowInfoDTO = {}
        this.$set(this.form.flowInfoDTO, 'status', '1') // 单据状态初始化
      }
      if (!this.form.activityTypeStdDTO) {
        this.form.activityTypeStdDTO = {}
      }

      // 总部项目名称，处理历史数据
      if (
        this.form.headQuartersProjectName &&
        !this.form.headQuartersProjectId
      ) {
        this.form.headQuartersProjectId = this.form.headQuartersProjectName
      }

      this.showNotification = status === '96'

      // 年场次提示信息
      this.overMeetUpperLimit(
        { yearMeetingTimeInfo: this.form.meetingsTimeStatistics } || {}
      )

      // 费用项发票
      if (this.form.cooptBillInfos)
        this.cooptBillInfos = cloneDeep(this.form.cooptBillInfos)

      this.$emit('status', status)
    },

    distributeData(module, isFee = false, type = 'add') {
      if (type !== 'add' || !isFee)
        Object.keys(module).forEach(key =>
          this.$set(module, key, this.form[key])
        )
      else {
        // 保留不被制空的预算字段
        const reserveList = [
          'reimaccompanyExpense', // 陪同人员费用
          'reimbudgetConsultTotal' // 讲者合计费用
        ]

        const saveUpperList = [
          'reimOtherFee', // 其他费用
          'reimRegisterFee', // 注册费
          'reimOnlineLiveBroadcastingFee' // 线上直播费用
        ]

        const propsList = [
          'budgetPreCost', // 已预付金额
          'reimAmountApprove', // 批准报销金额
          'financialApprovalAmount' // 财务批准报销金额
        ]

        for (const key of Object.keys(module)) {
          if (propsList.includes(key)) continue

          if (key.startsWith('reim')) {
            // 几个字段是驼峰式的
            const subKey = saveUpperList.includes(key)
              ? key
                  .slice(4)
                  .replace(key.slice(4)[0], key.slice(4)[0].toLocaleLowerCase())
              : key.slice(4)

            this.$set(module, key, this.form[subKey])

            // 跳过不需要置空的实际的字段
            if (!reserveList.includes(key)) {
              this.$set(module, subKey, '')
            }
          } else this.$set(module, key, this.form[key])
        }
      }
    },

    // 选择打印信息
    handldPrintlick() {
      this.print()
      this.showModal = false
    },

    replay() {
      this.$refs.comment.showLastComment()
      this.showNotification = false
      this.$nextTick(() => {
        this.$store.commit('rightContent/SCROLL_TO_END')
      })
    },

    // 学术申请单打印
    print() {
      this.assgin()
      this.printVisible = true
      this.$nextTick(() => this.$refs['printWrapper'].print())
    },

    // 打开申请单详情
    openApp(pk) {
      if (!pk) return this.$message.error('无法获取申请单')
      const http = getCrmHttp() + '/redirect'
      const token = this.$store.getters['token']
      const uid = this.$store.getters['uid']
      const url = `${http}?token=${token}&uid=${uid}&route=appForm&pid=${pk}&type=check&todo=true`
      const newWindow = window.open('', '_blank')
      newWindow.location.href = url
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.title || item.label
      const fileProp = item.upload || item.prop
      const module = item.upload ? this.fee : this.activity
      const isDeprecated =
        typeof item.deprecated === 'function'
          ? item.deprecated()
          : !!item.deprecated

      // 电子签到表
      if (fileProp === 'signinForm' && this.isAfterSignTable) {
        this.showSignTableModal = true
        return
      }

      if (this.isFlow || isDeprecated) {
        // 现场照片预览的
        if (fileProp === 'pictures' && this.isAfterPictures) {
          if (!this.picturesPriview) {
            getMeetingPhoto(this.form.activityAppId).then(res => {
              this.picturesPriview = JSON.stringify([
                ...(res.activPanoramaList || []),
                ...(res.otherList || []),
                ...(res.pptList || [])
              ])
              this.previewTitle = title
              this.previewFileListInfo = this.picturesPriview
              this.showPreview = true
            })
            return
          } else {
            this.previewTitle = title
            this.previewFileListInfo = this.picturesPriview
            this.showPreview = true
            return
          }
        }
        this.previewTitle = title
        this.previewFileListInfo = module[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = module[fileProp]
        this.showUpload = true
      }
    },

    filterData(apply) {
      const fileIds = []

      // 费用项的发票
      apply.cooptBillInfos = cloneDeep(this.cooptBillInfos)

      // 非线上
      if (!this.isShowOnline) {
        // 线上会议报告附件
        if (apply.onlineMeetingReport) {
          const files = JSON.parse(apply.onlineMeetingReport)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.onlineMeetingReport = ''
        }
      }

      // 非协办
      if (!this.isShowCo) {
        // 协办第三方附件
        if (apply.cooperationInfo) {
          const files = JSON.parse(apply.cooperationInfo)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.cooperationInfo = ''
        }
      }

      return apply
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '签到表':
          this.activity.signinForm = fileListInfo
          break
        case '现场照片':
          this.activity.pictures = fileListInfo
          break
        case '讲者咨询费凭证':
          this.activity.consultationFeeProof = fileListInfo
          break
        case '点评会讲者点评表':
          this.activity.speakerReviewForm = fileListInfo
          break
        case '协办第三方资料':
          this.activity.cooperationInfo = fileListInfo
          break
        case '线上会议报告':
          this.activity.onlineMeetingReport = fileListInfo
          break
        case '交通费':
          this.fee.trafficExpenseDocs = fileListInfo
          break
        case '接送费':
          this.fee.budgetReceptionDocs = fileListInfo
          break
        case '住宿费':
          this.fee.budgetAccommodationDocs = fileListInfo
          break
        case '会务物料制作费':
          this.fee.meetingMaterialExpenseDocs = fileListInfo
          break
        case '展台布展':
          this.fee.exhibitionExpenseDocs = fileListInfo
          break
        case '第三方会务人员费用':
          this.fee.budgetThirdpartyDocs = fileListInfo
          break
        case '注册费':
          this.fee.registerFeeDocs = fileListInfo
          break
        case '场地费':
          this.fee.venueExpenseDocs = fileListInfo
          break
        case '餐费':
          this.fee.budgetMealsDocs = fileListInfo
          break
        case '第三方协办费用':
          this.fee.budgetCooperationDocs = fileListInfo
          break
        case '会务服务费、税费':
          this.fee.budgetServiceDocs = fileListInfo
          break
        case '线上平台直播费用':
          this.fee.onlineLiveBroadcastingFeeDocs = fileListInfo
          break
        case '讲者劳务费':
          this.fee.budgetConsultDocs = fileListInfo
          break
        case '陪同费用':
          this.fee.accompanyExpenseDocs = fileListInfo
          break
        default:
          break
      }
    },

    assgin() {
      const user = cloneDeep(this.user)
      const activity = cloneDeep(this.activity)
      const standard = cloneDeep(this.standard)
      const accompany = cloneDeep(this.accompany)
      const speaker = cloneDeep(this.speaker)
      const fee = cloneDeep(this.fee)
      this.form = {
        ...this.form,
        ...user,
        ...activity,
        ...standard,
        ...accompany,
        ...speaker,
        ...fee
      }
    },

    overMeetUpperLimit({ yearMeetingTimeInfo = {} }) {
      const {
        activityTypeName,
        psnYearTimes = 0,
        yearTimesUpperLimit = 0
      } = yearMeetingTimeInfo

      if (psnYearTimes > yearTimesUpperLimit)
        this.$message.warning({
          content: `活动类型: ${activityTypeName} 、申请人: ${
            this.$store.getters.username
          } 会议申请已超过年场次上限${psnYearTimes - yearTimesUpperLimit}`,
          duration: 4
        })
    },

    //保存
    save(callback) {
      this.assgin()
      this.form = this.filterData(this.form)

      const submitData = cloneDeep(this.form)

      // 如果选择的是总部项目的id，不在枚举列表里，则把总部项目id制空（兼容历史单据）
      if (submitData.headQuartersProjectId) {
        const list = this.activityForm[14].options.map(item => item.value)
        if (!list.includes(submitData.headQuartersProjectId)) {
          submitData.headQuartersProjectId = ''
        }
      }

      const saveApi = this.form.id
        ? getEditActivityReimbursement
        : getActivityReimbursement

      saveApi(submitData)
        .then(res => {
          this.delUploadFile()
          this.$message.success('保存成功')
          this.$emit(this.status)
          if (this.showAvatar) window.close()
          if (!this.isUpdate) {
            this.overMeetUpperLimit(res)
            //未做更改，点击保存按钮
            this.$router.replace({ name: 'activityReimbursement' })
          } else {
            // 做更改保存，非点击按钮触发事件
            if (typeof callback === 'function') {
              callback(true)
            } else {
              //点击按钮触发事件
              this.isUpdate = false
              this.$router.replace({ name: 'activityReimbursement' })
            }
          }
        })
        .catch(() => {
          callback(false)
        })
    },

    //提交
    submit() {
      this.assgin()
      this.isUpdate = false
      this.form = this.filterData(this.form)
      const submitData = cloneDeep(this.form)

      // 如果选择的是总部项目的id，不在枚举列表里，则把总部项目id制空（兼容历史单据）
      if (submitData.headQuartersProjectId) {
        const list = this.activityForm[14].options.map(item => item.value)
        if (!list.includes(submitData.headQuartersProjectId)) {
          submitData.headQuartersProjectId = ''
        }
      }

      if (this.verityAppForm()) {
        getSubmitXSBX(submitData).then(res => {
          this.delUploadFile()
          this.overMeetUpperLimit(res)
          this.$message.success('提交成功')
          //门户保存crm单据时，直接关闭窗口
          if (this.showAvatar) window.close()
          this.$router.replace({ name: 'activityReimbursement' })
        })
      }
    },

    //删除
    del() {
      this.isUpdate = false
      this.$confirm({
        title: '是否要删除该学术推广活动总结单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getDeleteActivityReimbursement(this.form.id).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) window.close()
            this.$router.replace({ name: 'activityReimbursement' })
          })
        }
      })
    },

    // 撤销
    getBack() {
      this.$confirm({
        title: '是否要撤销该学术活动总结单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'XSBX',
            this.form.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDTO.status = '5'
            this.fee.reimAmountApprove = 0 //批准总报销为0
            this.$emit('status', '5')
          })
        }
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    }
  }
}
</script>

<style lang="less" scoped>
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
/deep/ .ant-table-thead {
  & > tr > th {
    font-size: 12px;
  }
}
/deep/ .ant-table-tbody > tr > td {
  font-size: 12px;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/.ant-form-item-label {
  line-height: 24px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
/deep/ .deprecated-row {
  background: #d9d9d9;
}
/deep/.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}

.app-form-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
</style>
