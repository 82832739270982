<template>
  <div>
    <a-modal
      v-model="visible"
      title="查询付款单位"
      width="50%"
      class="payment clearfix"
      :mask="false"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      @cancel="handleClose"
    >
      <div class="payment-content w-full">
        <!-- <div class="w-full my-3">
          <button class="button mr-2" @click="prev" :disabled="!prePage">
            上一页
          </button>
          <button class="button" @click="next" :disabled="!nextPage">
            下一页
          </button>
          <div class="search float-right">
            <a-input-search
              placeholder="收款单位"
              @search="onSearch"
              enterButton="搜索"
              class="w-72"
            />
          </div>
        </div> -->
        <a-table
          size="small"
          rowKey="id"
          class="m-2"
          :dataSource="datas"
          :columns="columns"
          :pagination="false"
          :loading="loading"
          :rowClassName="
            (record, index) => {
              return index % 2 === 0 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  dblclick: () => {
                    handleChoose(record)
                  }
                }
              }
            }
          "
        >
          <template slot="operation" slot-scope="text, record">
            <button class="button choose-btn" @click="handleChoose(record)">
              选择
            </button>
          </template>
          <template slot="detail" slot-scope="text, record">
            <a-icon
              type="eye"
              class="cursor-pointer hover:text-blue-500"
              @click="detailChoose(record)"
            />
          </template>
        </a-table>
      </div>
    </a-modal>
    <payname-detial-preview
      v-if="showDetial"
      :detialMsg="detialMsg"
      @close="showDetial = false"
    ></payname-detial-preview>
  </div>
</template>

<script>
import { getPayer } from '@/api/acdemicActive/acdemic-payment'
import PaynameDetialPreview from './PayNameDetialPreview'

const columns = [
  {
    title: '明细',
    key: 'detail',
    align: 'center',
    scopedSlots: { customRender: 'detail' }
  },
  {
    title: '付款单位',
    align: 'center',
    dataIndex: 'payName'
  },
  {
    title: '省区',
    align: 'center',
    dataIndex: 'province'
  },
  {
    title: '城市',
    dataIndex: 'city',
    align: 'center'
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'PayNamePreview',
  components: {
    PaynameDetialPreview
  },
  data() {
    return {
      visible: true,
      loading: false,
      columns,
      datas: [],
      showDetial: false, //付款单据明细默认关闭
      detialMsg: '' //传入明细窗口的付款单位信息
    }
  },
  created() {
    this.loading = true
    getPayer().then(response => {
      this.datas = response.filter(
        item =>
          (item.payName === '宜昌东阳光长江药业股份有限公司' &&
            item.payAccNum === '44284001040013042') ||
          item.payName !== '宜昌东阳光长江药业股份有限公司'
      )
      for (let i = 0; i < this.datas.length; i++) {
        this.datas[i].id = i
      }
      this.loading = false
    })
  },
  methods: {
    // 关闭收款窗
    handleClose() {
      this.$emit('close')
    },

    //选择的收款单位信息
    handleChoose(value) {
      this.$emit('payNameInfo', value)
      this.handleClose()
    },

    // 收款单位明细
    detailChoose(value) {
      this.detialMsg = value
      this.showDetial = true
    }

    // // 上一页
    // prev() {
    //   this.getReceive(this.current - 1, this.pageSize, this.map)
    // },

    // // 下一页
    // next() {
    //   this.getReceive(this.current + 1, this.pageSize, this.map)
    // }
  }
}
</script>

<style lang="less">
.payment {
  top: 2vh !important ;
}
.payment .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.payment .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.payment .ant-modal-close-x {
  width: 39px;
  height: 39px;
  line-height: 39px;
  color: #ffffff;
}
.payment .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.payment .ant-modal-body {
  padding: 0px;
  height: 60vh;
}
.payment .ant-btn-primary,
.payment .ant-btn-primary:hover {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
}
.payment .payment-content {
  padding: 0 8px;
  height: 58vh;
  overflow: auto;
}

.payment .payment-content .button {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px 0px #f0f7fa;
  -moz-box-shadow: 0px 1px 0px 0px #f0f7fa;
  box-shadow: 0px 1px 0px 0px #f0f7fa;
  color: #606266;
  font-size: 8px;
  padding: 4px 8px 4px 8px;
  border: solid #a0aec0 1px;
  text-decoration: none;
  &.choose-btn {
    padding: 4px 15px 4px 15px;
  }
  &:focus {
    outline: 0;
  }
}

.payment .payment-content .button:hover {
  border: solid #606266 1px;
  text-decoration: none;
}
</style>
