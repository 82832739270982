var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":((_vm.feeItem.title) + "发票"),"width":"85%","visible":_vm.visible,"dialogStyle":{ top: '48px' }},on:{"ok":_vm.handleOk,"cancel":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"w-full relative",staticStyle:{"height":"calc(100vh - 256px)"}},[_c('div',{staticClass:"my-2"},[(!_vm.isRead)?_c('a-button',{on:{"click":_vm.openInvioceChoose}},[_vm._v("选择票据")]):_vm._e(),(
          _vm.$route.query.type !== 'add' &&
          _vm.feeProjectEmunItem.text === '交通费' &&
          _vm.billInfos.length
        )?_c('a-button',{staticClass:"ml-2",on:{"click":_vm.exportInvioce}},[_vm._v(" 导出发票 ")]):_vm._e()],1),_c('a-table',{attrs:{"size":"small","rowKey":"pk","dataSource":_vm.billInfos,"columns":_vm.columns,"pagination":false,"scroll":{ x: 3600, y: 480 }},scopedSlots:_vm._u([{key:"index",fn:function(text, record, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"checkRender",fn:function(text, record){return [_c('span',{class:[!!record.checkIn ? 'text-green-600' : 'text-red-600']},[_vm._v(" "+_vm._s(!!record.checkIn ? '是' : '否')+" ")])]}},{key:"sourceRender",fn:function(text){return [_c('span',{class:_vm.getSourceColor(text)},[_vm._v(" "+_vm._s(_vm.getSourceLabel(text))+" ")])]}},{key:"statusRender",fn:function(text, record){return [_c('span',{class:[
            _vm.getRepeatConfig(text, 'color'),
            text == 1 && record.exceptions
              ? ' hover:text-blue-600 cursor-pointer'
              : ''
          ],on:{"click":function($event){return _vm.showRepeatInvoice(text, record)}}},[_vm._v(" "+_vm._s(_vm.getRepeatConfig(text, 'label'))+" ")])]}},{key:"operatRender",fn:function(text, record){return [_c('a-button',{attrs:{"title":"查看票据","type":"link","size":"small","icon":"eye"},on:{"click":function($event){return _vm.showInvoiceDetail(record)}}}),_c('a-popconfirm',{attrs:{"overlayClassName":"popover","title":"是否删除当前票据?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.delItem(record)}}},[(!_vm.isRead)?_c('a-button',{staticClass:"text-red-500",attrs:{"title":"删除","type":"link","size":"small","icon":"delete"}}):_vm._e()],1)]}},{key:"footer",fn:function(currentPageData){return [_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-xs text-gray-600 font-bold"},[_vm._v(" 发票金额合计: "),_c('span',{staticClass:"text-red-500 px-2"},[_vm._v(" "+_vm._s(currentPageData.reduce(function (aval, cval) { return _vm.$np.plus(Number(aval), Number(cval.total)) }, 0))+" ")]),_vm._v(" 元 ")])])]}}])})],1),_c('a-modal',{attrs:{"title":"请选择票据","width":"90%","bodyStyle":{ padding: '12px' },"dialogStyle":{ top: '48px' }},on:{"ok":_vm.chooseInvoice},model:{value:(_vm.showChooseVisible),callback:function ($$v) {_vm.showChooseVisible=$$v},expression:"showChooseVisible"}},[_c('a-table',{attrs:{"size":"small","rowKey":"pk","dataSource":_vm.datas,"columns":_vm.getColumns,"pagination":false,"scroll":{ x: 3600 },"rowClassName":function (record) {
          return _vm.allFeeItemInviocesPk.includes(record.pk)
            ? ' bg-gray-200 cursor-not-allowed'
            : ''
        },"row-selection":{
        getCheckboxProps: _vm.getCheckboxProps,
        columnWidth: 36,
        selectedRowKeys: _vm.selectedRowKeys,
        onSelect: _vm.onSelect,
        onSelectAll: _vm.onSelectAll
      }},scopedSlots:_vm._u([{key:"sourceRender",fn:function(text){return [_c('span',{class:[_vm.getSourceColor(text)]},[_vm._v(" "+_vm._s(_vm.getSourceLabel(text))+" ")])]}},{key:"checkTrue",fn:function(text){return [_c('span',{class:[
            text === 'Y'
              ? ' text-green-500'
              : text === 'N'
              ? 'text-red-500'
              : 'text-gray-500'
          ]},[_vm._v(" "+_vm._s(text === 'Y' ? '真' : text === 'N' ? '假' : '暂不支持验真')+" ")])]}},{key:"statusRender",fn:function(text){return [_c('span',{class:[_vm.getRepeatConfig(text, 'color')]},[_vm._v(" "+_vm._s(_vm.getRepeatConfig(text, 'label'))+" ")])]}},{key:"operatRender",fn:function(text, record){return [_c('span',{staticClass:"relative"},[_c('a-button',{attrs:{"title":"查看票据","type":"link","size":"small","icon":"eye"},on:{"click":function($event){return _vm.showInvoiceDetail(record)}}}),(_vm.allFeeItemInviocesPk.includes(record.pk))?_c('a-avatar',{style:({
              backgroundColor: '#2edfa3',
              verticalAlign: 'middle',
              right: '-8px',
              top: '-16px'
            }),attrs:{"shape":"square","title":"已被其他费用项引用","size":18}},[_vm._v(" 引 ")]):_vm._e()],1)]}}])}),_c('a-pagination',{staticClass:"text-right mt-4",attrs:{"showSizeChanger":"","showQuickJumper":"","current":_vm.current,"pageSize":_vm.pageSize,"total":_vm.total,"show-total":function (total) { return ("共 " + total + " 条"); }},on:{"change":_vm.pageChange,"showSizeChange":_vm.showSizeChange}})],1),_c('a-modal',{staticStyle:{"top":"32px"},attrs:{"title":"票据详情","width":"1024px","zIndex":9999,"maskClosable":false,"dialogStyle":{ top: '24px' },"footer":false,"bodyStyle":{
      padding: '12px',
      height: 'calc(100vh - 124px)',
      overflow: 'auto'
    }},model:{value:(_vm.invoicVisible),callback:function ($$v) {_vm.invoicVisible=$$v},expression:"invoicVisible"}},[_c('invoice-preview',{attrs:{"form":_vm.invoiceForm}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }